import jwtDecode from 'jwt-decode';
import LZString from 'lz-string';

export interface JWTPayload {
  AppID: string;
  Scope: string;
  // Roles: Role[];
  CompressedRolesString: string;
  Account: string;
  SubjectID: number;
  Name: string;
  WorkerNumber: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
}

export interface Role {
  id: number;
  tenant_id: number;
  name: string;
  tenant_subject_id: number;
  resource_with_actions: ResourceWithAction[];
  created_at: number;
  updated_at: number;
}

export interface ResourceWithAction {
  id: number;
  name: string;
  actions: Action[];
}

export interface Action {
  id: number;
  name: string;
}

export function getJWTPayload() {
  const jwtPayload: JWTPayload = jwtDecode(
    localStorage.getItem('accessToken') || '{}'
  );

  if (!jwtPayload?.CompressedRolesString) {
    throw new Error('invalid jwt payload');
  }

  const { CompressedRolesString, ...payload } = jwtPayload;

  const roles = LZString.decompressFromBase64(CompressedRolesString);

  return Object.assign(payload, { roles: JSON.parse(roles) as Role[] }) ?? {};
}

export function checkJWTRoles(action_ids: number[]) {
  console.log(getJWTPayload().roles);
  console.log(action_ids);
  const actions = getJWTPayload().roles.flatMap((role) =>
    role.resource_with_actions.flatMap((resource) =>
      resource.actions.map((action) => action.id)
    )
  );
  return actions.some((action) => action_ids.includes(action));
}
