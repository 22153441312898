import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  syncWorkOrdersOrdersFromSAP,
  SyncWorkOrdersOrdersFromSAPParams,
  useWorkOrders,
  WorkOrders,
  WorkOrdersRequestParams,
  WORK_ORDERS_STATES,
  checkJWTRoles,
} from '@solar/data';
import {
  FilterScaffold,
  MznPaginationTable,
  PageLayout,
} from '@solar/templates';
import moment from 'moment';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { filteredOrderTypeCodes, tabIds } from './const';
import { Filter } from './Filter';

type FormProps = Omit<
  WorkOrdersRequestParams,
  | 'createdAtDateFrom'
  | 'createdAtDateTo'
  | 'expectedCompletedAtDateFrom'
  | 'expectedCompletedAtDateTo'
  | 'factoryId'
  | 'orderTypeCode'
  | 'sortType'
  | 'states'
> & {
  createdDateRange: [string, string];
  expectedCompletedDateRange: [string, string];
  factories: SelectValue[];
  orderTypes: SelectValue[];
  sortType: SelectValue;
  states: SelectValue[];
};

export function WorkOrderManagementPage() {
  const [syncing, setSyncing] = useState(false);
  const methods = useForm<FormProps>();
  const navigate = useNavigate();

  const { orders, mutateParams, pageInfo, refetchOrders, isLoading } =
    useWorkOrders({
      initiateFetching: false,
    });

  const handleSyncSap = useCallback(
    async (param: SyncWorkOrdersOrdersFromSAPParams) => {
      setSyncing(true);
      try {
        const res = await syncWorkOrdersOrdersFromSAP(param);

        if (res.ok) {
          Message.success('同步成功');
        }

        await refetchOrders();
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error('同步失敗');
        }
      } finally {
        setSyncing(false);
      }
    },
    []
  );

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        createdAtDateFrom: (filterState.createdDateRange ?? [])[0],
        createdAtDateTo: (filterState.createdDateRange ?? [])[1],
        expectedCompletedAtDateFrom: (filterState.expectedCompletedDateRange ??
          [])[0],
        expectedCompletedAtDateTo: (filterState.expectedCompletedDateRange ??
          [])[1],
        orderTypeCodes: filterState?.orderTypes?.length
          ? filterState?.orderTypes.map((row) => row.id)
          : undefined,
        factoryIds: filterState?.factories?.map((row) => row.id),
        sortType: filterState?.sortType
          ?.id as WorkOrdersRequestParams['sortType'],
        states: filterState?.states?.map(
          (type) => type.id as keyof typeof WORK_ORDERS_STATES
        ),
        filteredOrderTypeCodes: filteredOrderTypeCodes, //filteredOrderTypeCodes,
      });
    },
    [methods, mutateParams, pageInfo]
  );

  const columns: TableColumn<WorkOrders>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          variant="outlined"
          type="button"
          onClick={() =>
            navigate({
              pathname: `${source['id']}`,
              search: qs.stringify({ tab: tabIds[0] }),
            })
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '狀態',
      dataIndex: 'workOrderStatusName',
    },
    {
      title: '建立日期',
      render: (source) =>
        source?.createdAt ? moment(source?.createdAt).format('YYYY/MM/DD') : '',
    },
    { title: '工廠', dataIndex: 'factoryId' },
    { title: '工單類型', dataIndex: 'orderTypeName' },
    { title: '工單單號', dataIndex: 'id' },
    {
      title: '訂單交期',
      render: (source) =>
        source?.expectedDeliveryDate
          ? moment(source?.expectedDeliveryDate).format('YYYY/MM/DD')
          : '',
    },
    { title: '銷售訂單號', dataIndex: 'salesOrderId' },
    { title: '銷售訂單項目', dataIndex: 'salesOrderLineId' },
    { title: '目標料號', dataIndex: 'materialId' },
    { title: '目標品名', dataIndex: 'materialDescription' },
    { title: 'MRP 控制員', dataIndex: 'mrpControllerName' },
    { title: '預期產出數量', dataIndex: 'expectedMainMaterialUnitQuantity' },
    { title: '單位', dataIndex: 'mainMaterialUnit' },
    { title: '已入庫數量', dataIndex: 'actualMainMaterialUnitQuantity' },
    { title: '單位', dataIndex: 'mainMaterialUnit' },
  ];

  useEffect(() => {
    refetchProductionDeliveryOrders({ nextPage: 1 });
  }, []);

  return (
    <PageLayout title="工單管理">
      <div>
        {checkJWTRoles([115]) && (
          <Button
            type="button"
            variant="outlined"
            loading={syncing}
            onClick={() => {
              const dateTo = moment().format('YYYYMMDD');
              const dateFrom = moment().subtract(6, 'd').format('YYYYMMDD');
              handleSyncSap({
                dateFrom,
                dateTo,
              });
            }}
          >
            重新整理
          </Button>
        )}
      </div>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchProductionDeliveryOrders}
          fetchAsParamsMutated={false}
        >
          <Filter />
          <Button
            type="button"
            variant="outlined"
            disabled={isLoading}
            onClick={() => refetchProductionDeliveryOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </FilterScaffold>
      </FormFieldsWrapper>
      <MznPaginationTable
        scroll={{ x: 2500 }}
        loading={isLoading}
        columns={columns}
        dataSource={orders ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
      />
    </PageLayout>
  );
}
