import {
  AutoComplete,
  Button,
  Message,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { Resolver, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import styles from './InStationPanel/in-station-panel.module.scss';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classes from './gen-params-panel.module.scss';
import { SiteInfoContext } from './const';
import { useProductionParameters } from './hooks/use-production-parameters';
import { ProductionParameters } from '../ProductionForm/ProductionParameters';
import { useWorkOrderInfo } from './hooks/use-work-order-info';
import {
  RowSection,
  useGetProductionForms,
  ProductionForms,
  PageLayout,
  useGetProductionFormsAnswers,
} from '@solar/templates';
import {
  saveAnswers,
  useEquipmentMaterials,
} from './hooks/use-equipment-materials';
import { FilterFormFields } from './EquipmentSelector';
import { API_NAMESPACE, PageInfo, useEquipment } from '@solar/data';
import useSWR from 'swr';
import { Descriptions, Pagination, Space } from 'antd';
import { useProcessingOptions } from './hooks/use-processing-options';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';

interface PackagingMetadata {
  id: number;
  packagingMiscMaterialId: string | null;
  customerId: string | null;
  materialId: string | null;
  packagingCount?: number | null;
  packagingUnit: string | null;
  firstOldMiscMaterialId: string | null;
  firstNewMiscMaterialId: string | null;
  secondOldMiscMaterialId: string | null;
  secondNewMiscMaterialId: string | null;
  thirdOldMiscMaterialId: string | null;
  thirdNewMiscMaterialId: string | null;
  firstOuterOldMiscMaterialId: string | null;
  firstOuterNewMiscMaterialId: string | null;
  firstOuterPackagingCount?: number | null;
  secondOuterOldMiscMaterialId: string | null;
  secondOuterNewMiscMaterialId: string | null;
  packagingMiscMaterialDescription?: string | null;
  firstNewMiscMaterialDescription?: string | null;
  secondNewMiscMaterialDescription?: string | null;
  thirdNewMiscMaterialDescription?: string | null;
  firstOuterNewMiscMaterialDescription?: string | null;
  secondOuterNewMiscMaterialDescription?: string | null;
  secondOuterPackagingCount?: string | null;
}

interface FormValues extends Record<string, any> {
  materialRequestOrderId: string;
  processingId: SelectValue | null;
  forms: Record<string, any>;
}

const defaultValues = {
  materialRequestOrderId: '',
};

export function GenParamsPanel({
  filterMethods,
}: {
  filterMethods: UseFormReturn<FilterFormFields>;
}) {
  const [resolver, setResolver] = useState<Resolver<FormValues>>();
  const methods = useForm<FormValues>({
    defaultValues,
    resolver,
  });

  const siteInfo = useContext(SiteInfoContext);

  const queryWorkOrderId = useWatch({
    name: 'materialRequestOrderId',
    control: methods.control,
  });

  const { workOrder, isLoading } = useWorkOrderInfo(queryWorkOrderId);

  const watchedWorkCenter = useWatch({
    name: 'workCenter',
    control: filterMethods.control,
  });

  const watchedEquipment = useWatch({
    name: 'equipment',
    control: filterMethods.control,
  });

  const { equipment } = useEquipment(watchedEquipment?.id);

  const { equipmentMaterials } = useEquipmentMaterials(
    watchedEquipment?.id ?? null
  );

  const hasHPK =
    /^HPK/.test(watchedWorkCenter?.id ?? '') ||
    /^HPK/.test(equipment?.workCenterId ?? '');

  const inputMaterialIds =
    equipmentMaterials?.inputMaterials?.map((im) => im?.inputMaterialId) ?? [];

  const params = useMemo(
    () => ({
      limit: 100,
      materialIds: inputMaterialIds,
    }),
    [inputMaterialIds]
  );

  const { data } = useSWR<{
    records: PackagingMetadata[];
    pageInfo: PageInfo;
  }>(
    hasHPK && inputMaterialIds.length > 0
      ? [
          '/sd/packaging-metadata',
          { namespace: API_NAMESPACE.MATERIALS, params },
        ]
      : null
  );

  const packagingMetadata = data?.records ?? [];

  const [selectedMaterial, setSelectedMaterial] =
    useState<SelectValue<string>>();

  const [metadataPointer, setMetadataPointer] = useState(0);

  const packagingMetadataByMaterial = useMemo(
    () =>
      packagingMetadata?.filter(
        (md) => md?.materialId === selectedMaterial?.id
      ),
    [packagingMetadata, selectedMaterial]
  );

  const targetDetail = packagingMetadataByMaterial?.[metadataPointer];

  const processingId = useWatch({
    name: 'processingId',
    control: methods.control,
  });

  const productionFormQueryConditions = useMemo(() => {
    if (
      (workOrder?.materialRequestOrderBatches?.length ?? 0) > 0 &&
      workOrder?.recipeId &&
      processingId?.id &&
      siteInfo.workCenterId &&
      siteInfo.equipmentId
    ) {
      const materialIdSet = new Set(
        workOrder?.materialRequestOrderBatches?.map(
          ({ materialId }) => materialId
        ) ?? []
      );

      return [...materialIdSet].map((materialId) => ({
        materialId,
        recipeId: workOrder?.recipeId ?? undefined,
        recipeVersion: workOrder?.recipeVersion ?? undefined,
        processingId: processingId?.id ?? undefined,
        workCenterId: siteInfo.workCenterId ?? undefined,
        equipmentId: siteInfo.equipmentId ?? undefined,
      }));
    }
    return undefined;
  }, [workOrder, siteInfo, processingId]);

  const {
    forms,
    formsMap,
    formsLoading,
    getFormSchema,
    isFormRendered,
    onFormRender,
  } = useGetProductionForms(productionFormQueryConditions);

  const parameters = useProductionParameters(productionFormQueryConditions);

  const productionFormAnswersQueryConditions = useMemo(() => {
    const defaultBatch = workOrder?.materialRequestOrderBatches?.[0];
    return defaultBatch
      ? [
          {
            materialId: defaultBatch?.materialId,
            materialSapBatchId: defaultBatch?.materialSapBatchId,
            materialSubBatchId: defaultBatch?.materialBatchId,
          },
        ]
      : [];
  }, [workOrder]);

  const material = useMemo(() => {
    const defaultBatch = workOrder?.materialRequestOrderBatches?.[0];
    return defaultBatch
      ? [
          defaultBatch?.materialId,
          defaultBatch?.materialSapBatchId,
          defaultBatch?.materialBatchId,
        ].join('_')
      : undefined;
  }, [workOrder]);

  useEffect(() => {
    if (material && forms?.length) {
      setResolver(() =>
        yupResolver(
          yup.object().shape({
            forms: yup.object().shape({
              [material]: getFormSchema(forms),
            }),
          })
        )
      );
    }
  }, [forms, material, getFormSchema]);

  const { formAnswersLoading, resetFormAnswersMap, compareFormAnswersFromMap } =
    useGetProductionFormsAnswers({
      methods,
      formsMap,
      registerName: 'forms',
      queryConditions: productionFormAnswersQueryConditions,
      isFormRendered,
    });

  const [saving, setSaving] = useState(false);

  const onSave = useCallback(async () => {
    try {
      setSaving(true);

      if (!material) {
        Message.error('無法取得材料資訊');
        return;
      }
      const formState = methods.getValues();
      const values = compareFormAnswersFromMap(material);

      if (isEmpty(values)) {
        Message.warning('無答案變更');
        return;
      }

      const answers = workOrder?.materialRequestOrderBatches?.flatMap((batch) =>
        Object.entries(values).map(([productionFormId, answer]) => ({
          productionFormId,
          answer,
          materialId: batch?.materialId,
          materialSapBatchId: batch?.materialSapBatchId,
          materialSubBatchId: batch?.materialBatchId,
        }))
      );

      if (!answers?.length) {
        Message.warning('無有效答案變更');
        return;
      }

      await saveAnswers({
        materialRequestOrderId: formState?.materialRequestOrderId,
        equipmentId: siteInfo?.equipmentId ?? undefined,
        answers,
      });

      resetFormAnswersMap();

      Message.success('儲存成功');
    } catch (error) {
      console.error(error);
      Message.error('儲存失敗');
    } finally {
      setSaving(false);
    }
  }, [workOrder, formsMap, compareFormAnswersFromMap]);

  const processingOptions = useProcessingOptions(
    siteInfo.workCenterId,
    workOrder
  );

  useEffect(() => {
    if ((workOrder?.workOrder?.operationCodes?.length ?? 0) > 0) {
      const defaultOption = workOrder?.workOrder?.operationCodes?.[0];
      if (defaultOption) {
        methods.setValue('processingId', {
          id: defaultOption.processingId,
          name: `（${defaultOption.processingId}）${defaultOption.processingName}`,
        });
      }
    } else {
      methods.setValue('processingId', null);
    }
  }, [workOrder, methods]);

  return (
    <PageLayout disabledDivider>
      <FormFieldsWrapper className={styles.formContainer} methods={methods}>
        <Space direction="vertical">
          <RowSection label="發料單號：">
            <InputField registerName="materialRequestOrderId" />
          </RowSection>
          <RowSection label="行為碼：">
            <SelectField
              registerName="processingId"
              options={processingOptions}
            />
          </RowSection>
        </Space>
        {forms?.length && material ? (
          <div className={styles.formContainer}>
            <Typography variant="h4">題目與生產資訊</Typography>
            <ProductionForms
              forms={forms}
              registerName={`forms.${material}`}
              loading={formsLoading || formAnswersLoading || saving}
              onFormRender={onFormRender}
            />
          </div>
        ) : null}
        {parameters?.length ? (
          <div className={styles.formContainer}>
            <Typography variant="h4">生產參數</Typography>
            <ProductionParameters parameters={parameters} />
          </div>
        ) : null}
        {(packagingMetadata?.length ?? 0) > 0 ? (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Descriptions column={1} bordered labelStyle={{ width: 180 }}>
              <Descriptions.Item label="成品料號">
                <AutoComplete
                  fullWidth
                  mode="single"
                  value={selectedMaterial}
                  onChange={setSelectedMaterial}
                  options={inputMaterialIds?.map((id) => ({
                    id,
                    name: id,
                  }))}
                />
              </Descriptions.Item>
              <Descriptions.Item label="包材料號">
                {targetDetail?.packagingMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="客戶編號">
                {targetDetail?.customerId}
              </Descriptions.Item>
              <Descriptions.Item label="包裝數量">
                {targetDetail?.packagingCount}
              </Descriptions.Item>
              <Descriptions.Item label="包裝單位">
                {targetDetail?.packagingUnit}
              </Descriptions.Item>
              <Descriptions.Item label="第一層品號(舊)">
                {targetDetail?.firstOldMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="第一層袋品號(新)">
                {targetDetail?.firstNewMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="品名">
                {targetDetail?.firstNewMiscMaterialDescription}
              </Descriptions.Item>
              <Descriptions.Item label="第二層品號(舊)">
                {targetDetail?.secondOldMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="第二層袋品號(新)">
                {targetDetail?.secondNewMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="品名">
                {targetDetail?.secondNewMiscMaterialDescription}
              </Descriptions.Item>
              <Descriptions.Item label="第三層品號(舊)">
                {targetDetail?.thirdOldMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="第三層袋品號(新)">
                {targetDetail?.thirdNewMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="品名">
                {targetDetail?.thirdNewMiscMaterialDescription}
              </Descriptions.Item>
              <Descriptions.Item label="外包裝品號1 ">
                {targetDetail?.firstOuterOldMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="包裝料號1">
                {targetDetail?.firstOuterNewMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="品名1">
                {targetDetail?.firstOuterNewMiscMaterialDescription}
              </Descriptions.Item>
              <Descriptions.Item label="外包裝數量1">
                {targetDetail?.firstOuterPackagingCount}
              </Descriptions.Item>
              <Descriptions.Item label="外包裝品號2">
                {targetDetail?.secondOuterOldMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="包裝料號2">
                {targetDetail?.secondOuterNewMiscMaterialId}
              </Descriptions.Item>
              <Descriptions.Item label="品名2">
                {targetDetail?.secondOuterNewMiscMaterialDescription}
              </Descriptions.Item>
              <Descriptions.Item label="外包裝數量2">
                {targetDetail?.secondOuterPackagingCount}
              </Descriptions.Item>
            </Descriptions>
            <Pagination
              current={metadataPointer + 1}
              total={packagingMetadataByMaterial?.length ?? 0}
              pageSize={1}
              onChange={(page) => setMetadataPointer(page - 1)}
            />
          </Space>
        ) : null}
        <Button
          type="button"
          disabled={formAnswersLoading || formsLoading}
          loading={saving}
          className={classes['save-btn']}
          onClick={onSave}
          variant="contained"
        >
          儲存
        </Button>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
