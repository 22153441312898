import { Button, Empty, Message, Typography } from '@mezzanine-ui/react';
import {
  DatePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  ProductionForms,
  RowSection,
  useGetFormValidation,
  useGetProductionForms,
  useGetProductionFormsAnswers,
} from '@solar/templates';
import {
  Resolver,
  UseFormReturn,
  useFieldArray,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { Col, Row, Space } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CheckoutItemCard } from './CheckoutItemCard';
import {
  checkOutMaterialsInEquipment,
  useEquipmentMaterials,
} from '../hooks/use-equipment-materials';
import { useProductionParameters } from '../hooks/use-production-parameters';
import { SiteInfoContext } from '../const';
import { ProductionParameters } from '../../ProductionForm/ProductionParameters';
import styles from '../InStationPanel/in-station-panel.module.scss';
import {
  CheckOutFormFields,
  checkOutFormDefaultValues,
} from './out-station-panel';
import { BarcodeScanInput } from '../../../Material/MaterialShift/BarcodeScanInput';
import { useWorkOrderInfo } from '../hooks/use-work-order-info';
import {
  equipmentMaterialsDefaultValues,
  MaterialListFormFields,
} from '../EquipmentMaterialsList';
import {
  ProductionFormFieldType,
  ProductionFormCondition,
  GetProductionFormAnswerParams,
} from '@solar/data';
import { ExpectedItem, UnexpectedItem } from '../typings';
import { useProcessingOptions } from '../hooks/use-processing-options';
import { uniqBy } from 'lodash';
import Decimal from 'decimal.js';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router';

export function OutStationPanel({
  methods,
  materialListMethods,
  setResolver,
}: {
  methods: UseFormReturn<CheckOutFormFields>;
  materialListMethods: UseFormReturn<MaterialListFormFields>;
  setResolver: Dispatch<
    SetStateAction<Resolver<CheckOutFormFields> | undefined>
  >;
}) {
  const location = useLocation();
  const isNormalManufacturing = location.pathname.includes(
    '/normal-manufacturing'
  );

  const [isCheckOut, setIsCheckOut] = useState(false);

  const siteInfo = useContext(SiteInfoContext);
  const equipmentId = siteInfo?.equipmentId;

  const materialCheckOutListMethods = useFieldArray({
    control: methods.control,
    name: 'materialCheckOutList',
  });

  const queryWorkOrderId = useWatch({
    name: 'materialRequestOrderId',
    control: methods.control,
  });

  const { workOrder, isLoading } = useWorkOrderInfo(queryWorkOrderId);

  const { equipmentMaterials, mutateGetEquipmentMaterials } =
    useEquipmentMaterials(equipmentId ?? null);

  const disabledCheckOut =
    (materialCheckOutListMethods?.fields?.length ?? -1) < 1;

  const handleSearchMaterialBarcode = useCallback(
    async (enteredValue?: string) => {
      const formState = methods?.getValues();
      const enteredMaterialBarcode = enteredValue?.trim();

      if (!enteredMaterialBarcode) {
        Message.error('批號條碼不得空白');
        return;
      }

      const targetMaterial = workOrder?.materialRequestOrderBatches?.find(
        (material) => material?.materialBarcode === enteredMaterialBarcode
      );

      if (!targetMaterial) {
        Message.error('批號條碼不存在預期產出物料中');
        return;
      }

      const isBarcodeExist = formState?.materialCheckOutList?.some((field) =>
        field?.isExpected
          ? field?.outputMaterialBarcode === enteredMaterialBarcode
          : false
      );

      if (isBarcodeExist) {
        Message.error(`${enteredMaterialBarcode} 條碼已存在`);
        return;
      }

      const materialId = targetMaterial?.materialId as string;

      if (!materialId) {
        Message.error('無效物料');
        return;
      }

      materialCheckOutListMethods?.prepend({
        isExpected: true,
        material: { id: materialId, name: materialId },
        outputMaterialBarcode: enteredMaterialBarcode,
      });

      methods.setValue('displayFormsByMaterialBarcode', enteredMaterialBarcode);
    },
    [methods, materialCheckOutListMethods, workOrder]
  );

  const processingId = useWatch({
    name: 'processingId',
    control: methods.control,
  });

  const processingOptions = useProcessingOptions(
    siteInfo.workCenterId,
    workOrder
  );

  useEffect(() => {
    if ((workOrder?.workOrder?.operationCodes?.length ?? 0) > 0) {
      const defaultOption = workOrder?.workOrder?.operationCodes?.[0];
      if (defaultOption) {
        methods.setValue('processingId', {
          id: defaultOption.processingId,
          name: `（${defaultOption.processingId}）${defaultOption.processingName}`,
        });
      }
    }
  }, [workOrder, methods]);

  const displayFormsByMaterialBarcode = useWatch({
    name: 'displayFormsByMaterialBarcode',
    control: methods.control,
  });

  const formAnswersQueryConditions = useMemo(() => {
    return materialCheckOutListMethods.fields?.reduce<
      Partial<GetProductionFormAnswerParams>[]
    >((result, item) => {
      if (item.isExpected) {
        const [materialId, materialSapBatchId, materialSubBatchId] =
          item?.outputMaterialBarcode?.split('_') ?? [];

        if (materialId && materialSapBatchId && materialSubBatchId) {
          return result.concat([
            {
              materialId,
              materialSapBatchId,
              materialSubBatchId,
            },
          ]);
        }
      }
      return result;
    }, []);
  }, [materialCheckOutListMethods.fields]);

  const productionFormQueryConditions = useMemo<ProductionFormCondition[]>(
    () =>
      formAnswersQueryConditions?.length &&
      workOrder?.recipeId &&
      processingId?.id &&
      siteInfo.workCenterId &&
      siteInfo.equipmentId
        ? formAnswersQueryConditions.map((condition) => ({
            materialId: condition?.materialId,
            recipeId: workOrder?.recipeId ?? undefined,
            recipeVersion: workOrder?.recipeVersion ?? undefined,
            processingId: processingId?.id ?? undefined,
            workCenterId: siteInfo.workCenterId ?? undefined,
            equipmentId: siteInfo.equipmentId ?? undefined,
          }))
        : [],
    [workOrder, siteInfo, formAnswersQueryConditions, processingId]
  );

  const {
    formsMap,
    formsLoading,
    resetFormsMap,
    getFormSchema,
    isFormRendered,
    onFormRender,
  } = useGetProductionForms(productionFormQueryConditions);

  useEffect(() => {
    setResolver(() =>
      yupResolver(
        yup.object().shape({
          forms: yup.object().shape(
            formAnswersQueryConditions.reduce<
              Record<string, yup.ObjectSchema<{}>>
            >((result, condition) => {
              if (
                condition?.materialId &&
                condition?.materialSapBatchId &&
                condition?.materialSubBatchId
              ) {
                const forms = formsMap?.get(condition.materialId);
                const key = [
                  condition?.materialId,
                  condition?.materialSapBatchId,
                  condition?.materialSubBatchId,
                ].join('_');

                if (forms?.length && key) {
                  return Object.assign({}, result, {
                    [key]: getFormSchema(forms),
                  });
                }
              }
              return result;
            }, {})
          ),
        })
      )
    );
  }, [setResolver, formsMap, formAnswersQueryConditions]);
  const parameters = useProductionParameters(productionFormQueryConditions);

  const forms = useMemo(() => {
    const materialId = displayFormsByMaterialBarcode?.split('_')?.[0];
    return formsMap?.get(materialId ?? '');
  }, [displayFormsByMaterialBarcode, formsMap]);

  const materialBatchId = useMemo(() => {
    const [materialId, materialSapBatchId, materialSubBatchId] = (
      displayFormsByMaterialBarcode ?? ''
    ).split('_');
    return [materialId, materialSapBatchId, materialSubBatchId].join('_');
  }, [displayFormsByMaterialBarcode]);

  const prependUnplannedProduction = () => {
    materialCheckOutListMethods?.prepend({ isExpected: false });
  };

  const formState = useFormState({
    control: methods.control,
    name: 'forms',
  });

  const { hasInvalidError, hasOOSError } = useGetFormValidation({
    formState,
    registerName: 'forms',
  });

  const { formAnswersLoading, resetFormAnswersMap, compareFormAnswersFromMap } =
    useGetProductionFormsAnswers({
      methods,
      formsMap,
      registerName: 'forms',
      queryConditions: formAnswersQueryConditions,
      isFormRendered,
    });

  const resetForm = useCallback(() => {
    setIsCheckOut(false);
    const materialRequestOrderId = methods?.getValues('materialRequestOrderId');
    methods?.reset(
      Object.assign(checkOutFormDefaultValues, {
        materialRequestOrderId,
        forms: {},
      })
    );
    methods.resetField('forms');
    materialListMethods.reset(equipmentMaterialsDefaultValues);
    materialListMethods.resetField('selectedEquipmentMaterials');
    resetFormsMap();
    resetFormAnswersMap();
  }, []);

  const handleCheckout = useCallback(
    async ({
      syncSap,
      status,
    }: {
      syncSap: boolean;
      status: 'NORMAL' | 'ABNORMAL';
    }) => {
      try {
        setIsCheckOut(true);
        await methods?.trigger();
        await materialListMethods.trigger();

        const checkoutForm = methods?.getValues();
        const equipmentId = siteInfo?.equipmentId;
        const materialRequestOrderId = checkoutForm?.materialRequestOrderId;

        if (!equipmentId) {
          Message.error('請選擇設備');
          return;
        }

        if (!materialRequestOrderId) {
          Message.error('請輸入發料單號');
          return;
        }

        const selectedEquipmentMaterials = materialListMethods.getValues(
          'selectedEquipmentMaterials'
        );

        if ((selectedEquipmentMaterials?.length ?? 0) === 0) {
          Message.error('請至少勾選一樣站內物料');
          return;
        }

        if (isNormalManufacturing) {
          const materialRequestOrderIdList = selectedEquipmentMaterials?.reduce<
            Set<string>
          >((result, item) => {
            const targetInputMaterial =
              equipmentMaterials?.inputMaterials?.find(
                (inputMaterial) =>
                  inputMaterial?.equipmentMaterialRecordId?.toString() ===
                  item?.id
              );

            if (targetInputMaterial?.materialRequestOrderId) {
              result.add(targetInputMaterial?.materialRequestOrderId);
            }

            return result;
          }, new Set([]));

          const validMaterialRequestOrderId =
            Array.from(materialRequestOrderIdList).length === 1 &&
            Array.from(materialRequestOrderIdList)?.[0] ===
              materialRequestOrderId;

          if (!validMaterialRequestOrderId) {
            Message.error('發料單號與站內物料的發料單號不符');
            return;
          }
        }

        const removedItems =
          materialListMethods.getValues('selectedEquipmentMaterials')?.reduce<
            {
              recordId: number;
              outputMaterialWeightUnitQuantity: string;
              outputMaterialStockUnitQuantity: string;
            }[]
          >((acc, item) => {
            if (item?.id) {
              acc.push({
                recordId: Number(item?.id),
                outputMaterialWeightUnitQuantity:
                  item?.outputMaterialWeightUnitQuantity,
                outputMaterialStockUnitQuantity: (() => {
                  switch (item?.outputMaterialUnit?.id) {
                    case 'stockUnit':
                      return item?.outputMaterialQuantity;
                    case 'inputUnit':
                      return new Decimal(item?.outputMaterialQuantity ?? '0')
                        .mul(item?.stockOverInputUnitRatio ?? '1')
                        .toString();
                    default:
                      return item?.outputMaterialQuantity;
                  }
                })(),
              });
            }
            return acc;
          }, []) ?? [];

        const [expectedItems, unexpectedItems] =
          checkoutForm?.materialCheckOutList?.reduce<
            [
              Array<
                Omit<ExpectedItem, 'isExpected' | 'outputMaterialInputUnit'> & {
                  outputMaterialInputUnit?: string;
                }
              >,
              Array<
                Omit<
                  UnexpectedItem,
                  'isExpected' | 'outputMaterialInputUnit'
                > & {
                  outputMaterialInputUnit?: string;
                }
              >
            ]
          >(
            ([tmpExpectedItems, tmpUnexpectedItems], item) => {
              if (item?.isExpected) {
                const key =
                  item?.outputMaterialBarcode
                    ?.split('_')
                    ?.slice(0, 3)
                    ?.join('_') ?? '';
                const ans = compareFormAnswersFromMap(key);
                const answers = Object.entries(ans)?.map(
                  ([productionFormId, answer]) => ({
                    productionFormId,
                    answer,
                  })
                );
                return [
                  tmpExpectedItems.concat([
                    Object.assign({}, item, {
                      answers,
                      toLoaderId: item?.toLoaderId?.id,
                      outputMaterialInputUnit:
                        item?.outputMaterialInputUnit?.id,
                    }),
                  ]),
                  tmpUnexpectedItems,
                ];
              }

              if (!item?.isExpected) {
                return [
                  tmpExpectedItems,
                  tmpUnexpectedItems.concat([
                    Object.assign({}, item, {
                      answers: [],
                      toLoaderId: item?.toLoaderId?.id,
                      outputMaterialId: item?.material?.id,
                      outputMaterialInputUnit:
                        item?.outputMaterialInputUnit?.id,
                    }),
                  ]),
                ];
              }

              return [tmpExpectedItems, tmpUnexpectedItems];
            },
            [[], []]
          );

        const response = await checkOutMaterialsInEquipment({
          equipmentId,
          materialRequestOrderId,
          status,
          createdAt: checkoutForm?.createdAt,
          items: expectedItems,
          unexpectedItems,
          removedItems,
          syncSap,
        });

        if (response?.ok) {
          await mutateGetEquipmentMaterials();
          Message.success('Check Out 完成');
          resetForm();
        }
      } catch (error: any) {
        Message.error(JSON.parse(error?.message ?? {})?.message ?? '發生錯誤');
      } finally {
        setIsCheckOut(false);
      }
    },
    [methods, methods.formState, equipmentMaterials]
  );

  const selectEquipmentMaterialsByRequestOrderId = useCallback(
    (requestOrderId: string) => {
      const prevSelectedEquipmentMaterials = materialListMethods?.getValues(
        'selectedEquipmentMaterials'
      );

      const currentSelectedEquipmentMaterials =
        equipmentMaterials?.inputMaterials
          ?.filter((input) => input.materialRequestOrderId === requestOrderId)
          ?.map((record) => ({
            id: record?.equipmentMaterialRecordId?.toString(),
            outputMaterialWeightUnitQuantity:
              record?.inputMaterialWeightUnitQuantity ?? '0',
            outputMaterialQuantity:
              record?.inputMaterialStockUnitQuantity ?? '0',
            outputMaterialUnit: {
              id: 'stockUnit',
              name: record?.inputMaterialStockUnit,
            } as const,
            outputMaterialStockUnitQuantity:
              record?.inputMaterialStockUnitQuantity ?? '0',
            stockOverInputUnitRatio: record?.stockOverInputUnitRatio,
          })) ?? [];

      const nextSelectedEquipmentMaterials = uniqBy(
        prevSelectedEquipmentMaterials.concat(
          currentSelectedEquipmentMaterials
        ),
        (item) => item?.id
      );

      materialListMethods?.setValue(
        'selectedEquipmentMaterials',
        nextSelectedEquipmentMaterials
      );
    },
    [equipmentMaterials]
  );

  return (
    <FormFieldsWrapper methods={methods} className={styles.panelContainer}>
      <RowSection label="發料單號：">
        <InputField
          registerName="materialRequestOrderId"
          onChange={(event) => {
            selectEquipmentMaterialsByRequestOrderId(event.target.value);
          }}
        />
      </RowSection>
      <RowSection label="行為碼：">
        <SelectField registerName="processingId" options={processingOptions} />
      </RowSection>
      <RowSection label="物料條碼：">
        <div
          style={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: 'auto 120px',
          }}
        >
          <BarcodeScanInput
            inputStyle={styles.scanInput}
            disableSearchBtn
            otherOnKeyDownAction={handleSearchMaterialBarcode}
          />
          <Button type="button" onClick={prependUnplannedProduction}>
            非計畫產出
          </Button>
        </div>
      </RowSection>
      <RowSection label="出站時間：">
        <DatePickerField
          registerName="createdAt"
          format="yyyy/MM/DD HH:mm:ss"
        />
      </RowSection>
      <Row gutter={[12, 12]}>
        <Col>
          <Button
            danger
            type="button"
            loading={isCheckOut || formsLoading || formAnswersLoading}
            variant="outlined"
            disabled={
              hasInvalidError ||
              (materialCheckOutListMethods?.fields?.length ?? -1) < 1
            }
            onClick={() =>
              handleCheckout({ syncSap: false, status: 'ABNORMAL' })
            }
          >
            異常待檢
          </Button>
        </Col>
        <Col>
          <Button
            type="button"
            loading={isCheckOut || formsLoading || formAnswersLoading}
            variant="outlined"
            disabled={hasInvalidError || hasOOSError || disabledCheckOut}
            onClick={() => handleCheckout({ syncSap: false, status: 'NORMAL' })}
          >
            Check Out
          </Button>
        </Col>
      </Row>
      <Space style={{ overflowX: 'scroll' }}>
        {materialCheckOutListMethods?.fields?.map((field, index) => (
          <CheckoutItemCard
            key={field?.id}
            field={field}
            index={index}
            remove={materialCheckOutListMethods?.remove}
            displayFormsByMaterialBarcode={displayFormsByMaterialBarcode}
          />
        ))}
      </Space>
      <div className={styles.formContainer}>
        <Typography variant="h4">
          題目與生產資訊（批號：{displayFormsByMaterialBarcode}）
        </Typography>
        {forms?.length ? (
          <ProductionForms
            forms={forms}
            registerName={`forms.${materialBatchId}`}
            loading={formsLoading || formAnswersLoading || isCheckOut}
            onFormRender={onFormRender}
          />
        ) : (
          <Empty title="無題目資料" />
        )}
      </div>
      {parameters?.length ? (
        <div className={styles.formContainer}>
          <Typography variant="h4">生產參數</Typography>
          <ProductionParameters parameters={parameters} />
        </div>
      ) : null}
    </FormFieldsWrapper>
  );
}
