import { TableColumn } from '@mezzanine-ui/core/table';
import { LogisticsDataType } from './RequirementManagement.interface';

export const exportCSVFile = (
  list: LogisticsDataType[] | undefined,
  detailedColumns: TableColumn<LogisticsDataType>[]
) => {
  const title = detailedColumns.map((column) => column.title).join(',') + '\n';
  const content = list
    ?.map((data, index) => {
      if (data.items && data.items.length > 0) {
        return data.items.map((item) => {
          return detailedColumns
            .map((column) => {
              const value = column.dataIndex === 'items'
                ? item[column.dataIndex as keyof typeof item]
                : data[column.dataIndex as keyof LogisticsDataType];
              if (column.render) {
                return column.render({ ...data, items: [item] }, index, column);
              }
              return value;
            })
            .map((value) => {
              if (value === null || value === undefined) {
                return '';
              }
              return `"${value}"`;
            })
            .join(',') + '\n';
        }).join('');
      } else {
        return detailedColumns
          .map((column) => {
            const value = data[column.dataIndex as keyof LogisticsDataType];
            if (column.render) {
              return column.render(data, index, column);
            }
            return value;
          })
          .map((value) => {
            if (value === null || value === undefined) {
              return '';
            }
            return `"${value}"`;
          })
          .join(',') + '\n';
      }
    })
    .join('');
  const fileName = 'Export.csv';
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(title + content)}`
  );
  link.setAttribute('download', fileName);
  link.click();
};
