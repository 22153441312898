import { Typography, Button } from '@mezzanine-ui/react';
import { Detail, useShipmentPackingPrintPage, useAccountData } from '@solar/data';
import { useEffect, useRef } from 'react';
import styles from './ShipmentPackingPrintPage.module.scss';
import { Descriptions, Table } from 'antd';
import { useReactToPrint } from 'react-to-print';

export function ShipmentPackingPrintPage() {
  const { data, tableData } = useShipmentPackingPrintPage();
  const { data: myaccountData } = useAccountData();
  const printRef = useRef<HTMLDivElement>(null);

  const topContent = [
    { label: '客戶名稱', value: data?.customerName ?? '' },
    { label: '出貨單編號', value: data?.id ?? '' },
    { label: '客戶代號', value: data?.customerId ?? '' },
    { label: '客戶電話', value: data?.customerPhone ?? '' },
    { label: '出貨日期', value: data?.shipDate ?? '' },
    { label: '出貨單位', value: data?.factoryId ?? '' },
    { label: '出貨種類', value: data?.typeName ?? '' },
    { label: '交貨方式', value: data?.method ?? '' },
    { label: '發票號碼', value: data?.invoice },
    { label: '指配地址', value: data?.address ?? '' },
    { label: '部門', value: data?.unit ?? '' },
    { label: '出貨類型', value: data?.processType ?? '' },
    { label: '收貨方聯絡人', value: data?.contact ?? '' },
    { label: '收貨方聯絡人電話', value: data?.contactPhone ?? '' },
  ];

  const columns = [
    {
      title: '客戶採購單號',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.purchaseOrderId}
          </Typography>
        );
      },
    },
    {
      title: '料號',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.materialId}
          </Typography>
        );
      },
    },
    {
      title: '品名',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.materialName}
          </Typography>
        );
      },
    },
    {
      title: '客戶料號',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.customerMaterialId}
          </Typography>
        );
      },
    },
    {
      title: '客戶品名',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.customerMaterialName}
          </Typography>
        );
      },
    },
    {
      title: '數量',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.quantity}
          </Typography>
        );
      },
    },
    {
      title: '單位',
      render: (source: Detail<string>) => {
        return (
          <Typography
            style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
          >
            {source.productUnit}
          </Typography>
        );
      },
    },
  ];

  const ref = useRef<boolean>(true);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (!tableData) return;
    const observer = new MutationObserver((list) => {
      const evt = new CustomEvent('dom-changed', { detail: list });
      document.body.dispatchEvent(evt);
    });
    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    const customEvent = () => {
      if (!ref.current) return;
      const allEle = document.querySelectorAll('.mzn-table__body__row');
      const length = allEle.length;
      if (length === tableData.length) {
        handlePrint();
        ref.current = false;
      }
    };
    document.body.addEventListener('dom-changed', customEvent);

    if (!ref.current)
      document.body.removeEventListener('dom-changed', customEvent);

    return () => {
      document.body.removeEventListener('dom-changed', customEvent);
    };
  }, [tableData]);

  return (
    <div>
      <Button variant="outlined" onClick={handlePrint}>
        列印
      </Button>
      <div
        style={{
          width: '29.7cm',
          minHeight: '21cm',
        }}
      >
        <div ref={printRef} className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <h5>光洋應用材料科技有限公司</h5>
            <h5>出貨單</h5>
          </div>
          <Descriptions
            bordered
            column={3}
            size="small"
            labelStyle={{ width: '3cm', color: 'black' }}
            contentStyle={{ width: '7cm', color: 'black' }}
          >
            {topContent.map((item, index) => (
              <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key={`${item.value}-${index}`} label={item.label}>{item.value}</Descriptions.Item>
            ))}
          </Descriptions>
          <Table style={{ marginTop: '0.2cm' }} columns={columns} dataSource={tableData ?? []} pagination={false} />
          <div className={styles.bottomContent}>
            <p>[備註]：</p>
            <p>{data?.note ?? ''}</p>
          </div>
          <br></br>
          <hr></hr>
          <Descriptions
            // bordered
            column={5}
            size="small"
            labelStyle={{ width: '2cm', color: 'black' }}
            contentStyle={{ width: '5cm', color: 'black' }}
          >
            <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key="boss" label="主管">{''}</Descriptions.Item>
            <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key="boss" label="業務">{data?.sales}</Descriptions.Item>
            <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key="boss" label="倉儲">{''}</Descriptions.Item>
            <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key="boss" label="製表人">{myaccountData?.me?.name}</Descriptions.Item>
            <Descriptions.Item labelStyle={{ color: 'black' }} contentStyle={{ color: 'black' }} key="boss" label="客戶/運送人簽收">{''}</Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    </div>
  );
}
