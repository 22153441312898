import { Button, Loading, Popper, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  FetchingInventoryStockTypes,
  getNextParams,
  InventoryByMaterialSearchType,
  NextPagination,
  ProductionAnswerDto,
  useGetInventoryByMaterial,
} from '@solar/data';
import { FilterScaffold, MznPaginationTable } from '@solar/templates';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocationViewerPopup } from '../ProductionPlanning/RoutingPage/use-location-viewer-popup';
import { Filter } from './Filter';
import { useMaterialViewBaseColumns } from './hooks/useColumns';
import { FilterFormType } from './typings';
import styles from './element-ratio.module.scss';
import moment from 'moment';

export type WarehouseEnquiryMaterialViewProps = {
  batchSerialModalOpen: () => void;
};

const stockTypes = [
  FetchingInventoryStockTypes.AVAILABLE,
  FetchingInventoryStockTypes.INSPECTION,
  FetchingInventoryStockTypes.IN_TRANSIT,
  FetchingInventoryStockTypes.SALES,
  FetchingInventoryStockTypes.REQUESTED,
  FetchingInventoryStockTypes.IN_TRANSIT_SALES,
  FetchingInventoryStockTypes.LOCKED,
  FetchingInventoryStockTypes.REQUESTED_SALES,
  FetchingInventoryStockTypes.HUB,
];

export function WarehouseEnquiryMaterialView({
  batchSerialModalOpen,
}: WarehouseEnquiryMaterialViewProps) {
  const methods = useForm<FilterFormType>({ defaultValues: {} });

  const {
    inventoryByMaterial,
    mutateGetInventoryByMaterial,
    pageInfo,
    isLoading,
  } = useGetInventoryByMaterial({
    type: InventoryByMaterialSearchType.LOADER_BARCODE,
    stockTypes,
  });

  const createdAtDateRange = methods.watch('createdAtDateRange');

  const refetchGetInventoryByMaterial = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateGetInventoryByMaterial({
        categoryIds: formState?.categories?.map((category) => category?.id),
        factoryIds: formState?.factories?.map((factory) => factory.id),
        groupIds: formState?.groups?.map((group) => group?.id),
        type: formState?.type?.id!,
        searchTerms: formState?.searchTerms,
        materialIds: formState?.materialIds,
        materialDescriptions: formState?.materialDescriptions,
        materialBatchIds: formState?.batchId ? [formState.batchId] : undefined,
        createdAtDateFrom: createdAtDateRange?.[0]
          ? moment(createdAtDateRange[0]).format('YYYYMMDD')
          : undefined,
        createdAtDateTo: createdAtDateRange?.[1]
          ? moment(createdAtDateRange[1]).format('YYYYMMDD')
          : undefined,
        stockTypes,
        offset,
        limit,
      });
    },
    [methods, mutateGetInventoryByMaterial, pageInfo]
  );

  const [locationPopup, setLocationViewerAnchor] =
    useLocationViewerPopup(methods);

  const [shownElementRatioInfo, setElementRatioInfo] = useState<
    ProductionAnswerDto[]
  >([]);

  const elementRatioAnchorRef = useRef<HTMLButtonElement | null>(null);

  const baseColumns = useMaterialViewBaseColumns({
    batchSerialModalOpen,
    methods,
    setLocationViewerAnchor,
    setElementRatioInfo,
    elementRatioAnchorRef,
  });

  if (!inventoryByMaterial) return <Loading loading />;

  return (
    <>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchGetInventoryByMaterial}
          fetchAsParamsMutated={false}
        >
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <div style={{ display: 'flex', gap: '12px' }}>
        <Button
          variant="outlined"
          type="button"
          onClick={() => refetchGetInventoryByMaterial({ nextPage: 1 })}
        >
          搜尋
        </Button>
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <MznPaginationTable
          loading={isLoading}
          scroll={{ fixedFirstColumn: true, x: 3500 }}
          style={{ height: '50vh' }}
          pageInfo={pageInfo ?? {}}
          fetchData={refetchGetInventoryByMaterial}
          dataSource={
            inventoryByMaterial?.map((inventory) => ({
              id: inventory?.recordId,
              ...inventory,
            })) ?? []
          }
          columns={baseColumns}
        />
        <Popper
          anchor={elementRatioAnchorRef}
          open={!!shownElementRatioInfo.length}
        >
          <div className={styles.elementRatioContainer}>
            <Typography variant="h5">元素比例</Typography>
            {shownElementRatioInfo.map((elementRatio) => (
              <Typography variant="body1">
                {elementRatio.form.title}：
                {elementRatio.modifiedAnswer || elementRatio.answer}{' '}
                {elementRatio.form.suffix}
              </Typography>
            ))}
          </div>
        </Popper>
      </div>
      {locationPopup}
    </>
  );
}
