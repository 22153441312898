import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import styles from './RequirementsManagementPage.module.scss';
import { Button } from '@mezzanine-ui/react';
import { useCallback, useState } from 'react';
import { Filter } from './Filter';
import { SearchResult } from './SearchResult';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import { TableTypes } from './RequirementsManagement.config';
import {
  LogisticSearchResData,
  LogisticsDataType,
  LogisticsListQuery,
} from './RequirementManagement.interface';

declare global {
  interface Window {
    printData: LogisticsDataType[] | undefined;
  }
}

export function RequirementsManagementPage() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [initialFetch, setInitialFetch] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tableType, setTableType] = useState(TableTypes.Brief);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { data, mutate } = useSWR<LogisticSearchResData>(
    initialFetch
      ? [
          `/logistics?offset=${offset}&limit=${limit}${
            query ? `&${query}` : ''
          }`,
          { namespace: '/sd/sd' },
        ]
      : null
  );

  const onSearch = useCallback(
    (filters: LogisticsListQuery) => {
      if (!initialFetch) {
        filters.request_status = 'NOT_VOID'; // 進頁面先撈未作廢資料
      }
      setQuery(new URLSearchParams(filters).toString());
      setOffset(0);
      setInitialFetch(true);
    },
    [initialFetch]
  );

  const onPrint = useCallback(() => {
    if (!selectedIds.length) return;

    const printData = data?.data.filter((item: LogisticsDataType) =>
      selectedIds.includes(item.id)
    );

    const printPage = window.open(
      '/transportation/requirements-management/print',
      '',
      'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=no'
    ) as Window;

    printPage.printData = printData;
  }, [data, selectedIds]);

  const onPageChange = (nextPage: number) => setOffset((nextPage - 1) * limit);
  const onPageSizeChange = (nextPageSize: number) => setLimit(nextPageSize);
  const onListChange = () => mutate();

  return (
    <PageTemplate
      title="需求管理"
      extraContent={
        <Button
          variant="contained"
          size="large"
          className={styles.newBtn}
          onClick={() => navigate('../requirement/new')}
        >
          新增
        </Button>
      }
    >
      <Filter
        canPrint={selectedIds.length > 0}
        onSearch={onSearch}
        onPrint={onPrint}
        onTypeChange={(type) => setTableType(type)}
      ></Filter>
      <SearchResult
        data={data}
        current={offset / limit + 1}
        pageSize={limit}
        tableType={tableType}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onListChange={onListChange}
        onSelectedIdsChange={setSelectedIds}
      ></SearchResult>
    </PageTemplate>
  );
}
