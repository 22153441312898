import BwipJs, { RenderOptions } from 'bwip-js';
import { useEffect } from 'react';

type DataMatrixProps = Omit<RenderOptions, 'bcid'> & {
  canvasId: string;
};

export function DataMatrix({ canvasId, text, width, height }: DataMatrixProps) {
  useEffect(() => {
    console.log('set', text);
    BwipJs.toCanvas(canvasId, {
      bcid: 'datamatrix',
      text,
      scale: 3,
      width,
      height,
      includetext: true,
      textxalign: 'center',
    });
  }, [canvasId, height, text, width]);

  return <canvas id={canvasId} />;
}
