import { Button, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  useGetEquipments,
  useGetWorkCenterBigClasses,
  useGetWorkCenterSmallClasses,
  useWorkCenters,
} from '@solar/data';
import {
  LOCATION_SELECTOR,
  LocationIdsAutocomepleteField,
  LocationSelectorGroupModal,
  ModalGroup,
  RowSection,
  useModalGroupController,
} from '@solar/templates';
import { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const defaultValues = {
  locationIds: [],
  workCenterBigClass: null,
  workCenterSmallClass: null,
  workCenter: null,
  equipment: null,
  equipmentSearchTerm: '',
};

export type FilterFormFields = {
  locationIds: SelectValue | null;
  workCenterBigClass: SelectValue | null;
  workCenterSmallClass: SelectValue | null;
  workCenter: SelectValue | null;
  equipment: SelectValue | null;
  equipmentSearchTerm: string;
};

export function EquipmentSelector({
  methods,
}: {
  methods: UseFormReturn<FilterFormFields>;
}) {
  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  const selectedWorkCenterBigClass = methods.watch('workCenterBigClass');

  const { workCenterBigClasses } = useGetWorkCenterBigClasses();
  const workCenterBigClassOptions =
    workCenterBigClasses?.map((bigClass) => ({
      id: bigClass?.bigClassCode,
      name: bigClass?.bigClassName,
    })) ?? [];

  const { workCenterSmallClasses } = useGetWorkCenterSmallClasses({
    bigClassCode: selectedWorkCenterBigClass?.id ?? '',
  });
  const workCenterSmallClassOptions = workCenterSmallClasses?.map(
    (smallClass) => ({
      id: smallClass?.smallClassCode,
      name: smallClass?.smallClassName,
    })
  );

  const { workCenters } = useWorkCenters();
  const workCenterOptions = workCenters
    ?.filter((wc) => wc?.id && wc?.name)
    ?.map((wc) => ({
      id: wc?.id,
      name: `${wc?.name}(${wc?.id})`,
    }));

  const { equipments, fetchEquipments } = useGetEquipments();
  const equipmentOptions = equipments?.map((eq) => ({
    id: eq?.id,
    name: `${eq?.name}(${eq?.workCenter?.id})`,
  }));

  const watchedEquipment = methods.watch('equipment');

  const refetchEquipments = useCallback(() => {
    const formState = methods?.getValues();
    fetchEquipments({
      bigClassCodes: formState?.workCenterBigClass?.id
        ? [formState?.workCenterBigClass?.id]
        : [],
      smallClassCodes: formState?.workCenterSmallClass?.id
        ? [formState?.workCenterSmallClass?.id]
        : [],
      locationIds: formState?.locationIds ? [formState?.locationIds?.id] : [],
      workCenterIds: formState?.workCenter ? [formState?.workCenter?.id] : [],
      searchTerm: formState?.equipmentSearchTerm,
    });
  }, [fetchEquipments, methods]);

  const appendLocationIds = (selectedLocationId?: string) => {
    if (selectedLocationId) {
      methods?.setValue('locationIds', {
        id: selectedLocationId,
        name: selectedLocationId,
      });
      methods?.setValue('equipment', null);
      refetchEquipments();
    }
  };

  return (
    <FormFieldsWrapper
      methods={methods}
      style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
    >
      <RowSection label="地點選取：">
        <LocationIdsAutocomepleteField
          mode="single"
          registerName="locationIds"
          onChange={() => {
            methods?.setValue('equipment', null);
            refetchEquipments();
          }}
          openModal={() => {
            modalGroupController?.openModal(LOCATION_SELECTOR, null);
          }}
        />
      </RowSection>
      <RowSection label="工作中心大類：">
        <SelectField
          key={JSON.stringify(methods.watch('workCenterBigClass'))}
          clearable
          disablePortal
          registerName="workCenterBigClass"
          options={workCenterBigClassOptions}
          onChange={() => {
            methods?.setValue('workCenterSmallClass', null);
            methods?.setValue('equipment', null);
            refetchEquipments();
          }}
        />
      </RowSection>
      <RowSection label="工作中心小類：">
        <SelectField
          clearable
          disablePortal
          key={JSON.stringify(methods.watch('workCenterSmallClass'))}
          registerName="workCenterSmallClass"
          options={workCenterSmallClassOptions}
          onChange={() => {
            methods?.setValue('equipment', null);
            refetchEquipments();
          }}
        />
        <Button
          type="button"
          onClick={() => {
            methods?.setValue('workCenterBigClass', null);
            methods?.setValue('workCenterSmallClass', null);
            methods?.setValue('equipment', null);
            refetchEquipments();
          }}
        >
          清空選擇
        </Button>
      </RowSection>
      <RowSection label="工作中心：">
        <AutoCompleteField
          registerName="workCenter"
          options={workCenterOptions ?? []}
          onChange={() => {
            methods?.setValue('equipment', null);
            refetchEquipments();
          }}
        />
      </RowSection>
      <RowSection label="設備：">
        <AutoCompleteField
          required
          registerName="equipment"
          options={equipmentOptions ?? []}
          onSearch={(searchTerm) => {
            methods?.setValue('equipmentSearchTerm', searchTerm);
            refetchEquipments();
          }}
        />
      </RowSection>
      <RowSection label="線邊倉：">
        {
          equipments?.find(
            (equipment) => equipment?.id === watchedEquipment?.id
          )?.zone?.id
        }
      </RowSection>
      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal
          showShelfSvg={false}
          onSubmit={appendLocationIds}
        />
      </ModalGroup>
    </FormFieldsWrapper>
  );
}
