import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import { CalendarConfigProvider } from '@mezzanine-ui/react';
import { request } from '@solar/data';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';
import styles from './app.module.scss';
import { EquipmentListPage } from './Equipment/EquipmentListPage/EquipmentListPage';
import { EquipmentMaintenancePage } from './Equipment/EquipmentMaintenancePage/EquipmentMaintenancePage';
import { FacilitiesElectricPage } from './Facilities/FacilitiesElectricPage/FacilitiesElectricPage';
import { HumanResourceBonusPage } from './HumanResource/HumanResourceBonusPage/HumanResourceBonusPage';
import { HumanResourceBUManagePage } from './HumanResource/HumanResourceBUManagePage/HumanResourceBUManagePage';
import { HumanResourceBUTeamPage } from './HumanResource/HumanResourceBUTeamPage/HumanResourceBUTeamPage';
import { HumanResourceMembersPage } from './HumanResource/HumanResourceMembersPage/HumanResourceMembersPage';
import { HumanResourceReportDetailsPage } from './HumanResource/HumanResourceReportPage/HumanResourceReportDetailsPage/HumanResourceReportDetailsPage';
import { HumanResourceReportPage } from './HumanResource/HumanResourceReportPage/HumanResourceReportPage';
import { MainBoard } from './MainBoard/MainBoard';

import { AppSideBarWrapper } from './AppSideBarWrapper';
import { LoginPage } from './Auth/LoginPage/LoginPage';
import { PageGuard } from './Auth/PageGuard/PageGuard';
import RolePage from './Auth/RolePage/RolePage';
import TenantPage from './Auth/TenantPage/TenantPage';
import { HubWarehousePage } from './SalesAndDistribution/HubWarehouse/HubWarehousePage';
import { CusTomerSupplyWarehousePage } from './CustomerSupply/CustomerSupplyWarehousePage';
import { AttributeCodeMappingPage } from './Drawing/AttributeCodeMappingPage/AttributeCodeMappingPage';
import { CreateDrawingPage } from './Drawing/CreateDrawingPage/CreateDrawingPage';
import { DrawingDetailPage } from './Drawing/DrawingDetailPage/DrawingDetailPage';
import { FCCDrawingDetailPage } from './Drawing/DrawingDetailPage/FCCDrawingDetailPage';
import { TEMDrawingDetailPage } from './Drawing/DrawingDetailPage/TEMDrawingDetailPage';
import { DrawingPage } from './Drawing/DrawingPage/DrawingPage';
import { FCCDrawingPage } from './Drawing/DrawingPage/FCCDrawingPage';
import { TEMDrawingPage } from './Drawing/DrawingPage/TEMDrawingPage';
import { EditDrawingPage } from './Drawing/EditDrawingPage/EditDrawingPage';
import { FCCEditDrawingPage } from './Drawing/EditDrawingPage/FCCEditDrawingPage';
import { TEMEditDrawingPage } from './Drawing/EditDrawingPage/TEMEditDrawingPage';
import { PendingDrawingsPage } from './Drawing/PendingDrawingsPage/PendingDrawingsPage';
import { GrafanaPage } from './Grafana/Grafana/Grafana';
import { HumanResourceChartPage } from './HumanResource/HumanResourceChartPage/HumanResourceChartPage';
import { WarehouseModule } from './Material/constant';
import { IncomingInspectDetailPage } from './Material/IncomingInspectOperationPage/IncomingInspectDetailPage';
import { IncomingInspectOperationPage } from './Material/IncomingInspectOperationPage/IncomingInspectOperationPage';
import { TempDetailPage } from './Material/IncomingInspectOperationPage/TempDetailPage';
import { CreateLoaderPage } from './Material/LoaderManagementPage/CreateLoaderPage';
import { LoaderManagementDetailPage } from './Material/LoaderManagementPage/LoaderManagementDetailPage';
import { LoaderManagementPage } from './Material/LoaderManagementPage/LoaderManagementPage';
import { CreateMaterialRegisterOrderPage } from './Material/MaterialCollectPage/create/CreateMaterialRegisterOrderPage';
import { MaterialCollectPage } from './Material/MaterialCollectPage/MaterialCollectPage';
import { MaterialMaintainPage } from './Material/MaterialMaintainPage/MaterialMaintainPage';
import { MaterialMappingPage } from './Material/MaterialMappingPage/MaterialMappingPage';
import { MaterialReturnOrderDetailPage } from './Material/MaterialReturnOrderPage/MaterialReturnOrderDetailPage';
import { MaterialReturnOrderPage } from './Material/MaterialReturnOrderPage/MaterialReturnOrderPage';
import { MaterialScrappingPage } from './Material/MaterialScrappingPage/MaterialScrappingPage';
import { GeneratedStorageIn } from './Material/MaterialShift/GeneratedStorageIn';
import { MaterialShiftPage } from './Material/MaterialShift/MaterialShiftPage';
import { MaterialTransferPage } from './Material/MaterialTransferPage/MaterialTransferPage';
import { MaterialViewerPage } from './Material/MaterialViewerPage/MaterialViewerPage';
import MiscellaneousDispatchAndReturnPage from './Material/MiscellaneousDispatchPage/MiscellaneousDispatchAndReturnPage';
import { MiscellaneousStorageInDetailPage } from './Material/MiscellaneousStorageInPage/MiscellaneousStorageInDetailPage';
import { MiscellaneousStorageInPage } from './Material/MiscellaneousStorageInPage/MiscellaneousStorageInPage';
import { OperationHistoryPage } from './Material/OperationHistoryPage/OperationHistoryPage';
import { toDashConcatCase } from './Material/utils/toDashConcatCase';
import { CostWarehousePage } from './MaterialEnquiry/CostWarehousePage/CostWarehousePage';
import { OrderManagementPage } from './OrderManagement/OrderManagementPage/OrderManagementPage';
import { MaterialAllocationsPage } from './ProductionPlanning/MaterialAllocationsPage/MaterialAllocationsPage';
import { PackagingStationDetailPage } from './ProductionPlanning/PackagingStationPage/PackagingStationDetailPage';
import { PackagingStationPage } from './ProductionPlanning/PackagingStationPage/PackagingStationPage';
import { RoutingPage } from './ProductionPlanning/RoutingPage/RoutingPage';
import { WorkOrderPage } from './ProductionPlanning/WorkOrderPage/WorkOrderPage';
import { UsagePredictionPage } from './ProductionPlanning/UsagePrediction/UsagePrediction';
import { QualificationReportPage } from './Qualifications/ReportPage/ReportPage';
import { HubDeliveryOrderPage } from './SalesAndDistribution/HubDeliveryOrderPage/HubDeliveryOrderPage';
import { HubReceivingOrderPage } from './SalesAndDistribution/HubReceivingOrderPage/HubRecevingOrderPage';
import { MainFilePage } from './SalesAndDistribution/MainFilePage/MainFilePage';
import { OrderMarksPage } from './SalesAndDistribution/OrderMarksPage/OrderMarksPage';
import { PackagingEstimate } from './SalesAndDistribution/PackagingEstimate/PackagingEstimate';
import { PackagingPage } from './SalesAndDistribution/PackagingPage/PackagingPage';
import { ShipmentPackingDetailPage } from './SalesAndDistribution/ShipmentPackingDetailPage/ShipmentPackingDetailPage';
import { ShipmentPackingPage } from './SalesAndDistribution/ShipmentPackingPage/ShipmentPackingPage';
import { ShipmentPackingPrintPage } from './SalesAndDistribution/ShipmentPackingPrintPage/ShipmentPackingPrintPage';
import { ShipmentBoxMergePage } from './SalesAndDistribution/ShipmentBoxMergePage/ShipmentBoxMergePage';
import { MaterialSupplyPage } from './Warehouse/MaterialSupplyPage/MaterialSupplyPage';
import { DeliveryOrderDetailPage } from './Warehouse/MaterialSupplyPage/DeliveryOrderDetailPage';
import { MaterialSupplyToOutsourcingOrderPage } from './Warehouse/MaterialSupplyToWorkOrderPage/MaterialSupplyToOutsourcingOrderPage';
import { MaterialSupplyToReturnedOrderPage } from './Warehouse/MaterialSupplyToWorkOrderPage/MaterialSupplyToReturnedOrderPage';
import { MaterialSupplyToWorkOrderPage } from './Warehouse/MaterialSupplyToWorkOrderPage/MaterialSupplyToWorkOrderPage';
import { ReturnMaterialDetailPage } from './Warehouse/ReturnMaterialDetailPage/ReturnMaterialDetailPage';
import { ReturnMaterialPage } from './Warehouse/ReturnMaterialPage/ReturnMaterialPage';
import { WarehouseMapPage } from './Warehouse/WarehouseMapPage/WarehouseMapPage';
import { WarehouseTempListPage } from './Warehouse/WarehouseTempListPage/WarehouseTempListPage';
import { WarehouseEnquiry } from './WarehouseEnquiry/WarehouseEnquiry';
import { MaterialCodeConfiguration } from './Material/MaterialCodeConfiguration/MaterialCodeConfiguration';
import { MaterialCodeConfigurationCreatePage } from './Material/MaterialCodeConfiguration/MaterialCodeConfigurationCreatePage';
import { MaterialCodeConfigurationDetailPage } from './Material/MaterialCodeConfiguration/MaterialCodeConfigurationDetailPage';
import { MaterialCodeTransform } from './Material/MaterialCodeConfiguration/MaterialCodeTransform';
import { MaterialCodeTransformDetailPage } from './Material/MaterialCodeConfiguration/MaterialCodeTransformDetailPage';
import { DispatchOrderPage } from './ProductionPlanning/DispatchOrderPage/DispatchOrderPage';
import { DispatchOrderDetailPage } from './ProductionPlanning/DispatchOrderPage/DispatchOrderDetailPage';
import { HumanResourceEmpDetailPage } from './HumanResource/HumanResourceEmpDetailPage/HumanResourceEmpDetailPage';
import { NormalManufacturingPage } from './ProductionPlanning/NormalManufacturingPage/NormalManufacturingPage';
import { HumanResourceEmpPage } from './HumanResource/HumanResourceEmpPage/HumanResourceEmpPage';
import { MaterialReceiptCheckPage } from './Material/MaterialReceiptCheckPage/MaterialReceiptCheckPage';
import { TempLoaderManagementPage } from './Material/LoaderManagementPage/TempLoaderManageMentPage';
import { WorkOrderManagementPage } from './ProductionPlanning/WorkOrderManagementPage/WorkOrderManagementPage';
import { WorkOrderManagementDetailPage } from './ProductionPlanning/WorkOrderManagementPage/WorkOrderManagementDetailPage';
import { MiscMaterialInventoryPage } from './Warehouse/MiscMaterialInventoryPage/MiscMaterialInventoryPage';
import { HumanResourceDepartmentInformationPage } from './HumanResource/HumanResourceDepartmentInformationPage/HumanResourceDepartmentInformationPage';
import { LabelPage } from './PrintManagement/LabelPage/LabelPage';
import { WorkOrderPage as PrintManagementWorkOrderPage } from './PrintManagement/WorkOrderPage/WorkOrderPage';
// import { WorkOrderRecipePage } from './ProductionPlanning/WorkOrderPage/WorkOrderRecipePage';
import { RequirementPage } from './Transportation/RequirementPage/RequirementPage';
import { ManagementPage } from './Transportation/ManagementPage/ManagementPage';
import { BillOfMaterialPage } from './Transportation/BillOfMaterial/BillOfMaterial';
import { WorkOrderPrintPage } from './Transportation/WorkOrderPrint/WorkOrderPrint';
import { RequirementsManagementPage } from './Transportation/RequirementsManagementPage/RequirementsManagementPage';
import { SalesReturnPage } from './SalesAndDistribution/SalesReturnPage/SalesReturnPage';
import { SalesReturnDetailPage } from './SalesAndDistribution/SalesReturnDetailPage/SaleReturnDetailPage';
import { RecipePage } from './ProductionPlanning/RecipePage/RecipePage';
import { PrintPage } from './Transportation/RequirementsManagementPage/PrintPage/PrintPage';
import { RecipeTablePage } from './ProductionPlanning/RecipePage/RecipeTablePage';
import IngredientSearch from './IngredientSearch/IngredientSearch';
import OrderPredictSearchPage from './OrderManagement/OrderPredictSearchPage/OrderPredictSearchPage';
import OrderPredictUpsertPage from './OrderManagement/OrderPredictUpsertPage/OrderPredictUpsertPage';
import StrikePriceSearch from './StrikePrice/StrikePriceSearch';
import StrikePriceUpsert from './StrikePrice/StrikePriceUpsert';
import { FormTemplatePage } from './ProductionPlanning/FormTemplate/FormTemplatePage';
import { TempCustomerSupplyDetailPage } from './Material/IncomingInspectOperationPage/TempCustomerSupplyDetailPage';
import { Inventory } from './Material/Inventory/Inventory';
import { InventoryDetail } from './Material/Inventory/InventoryDetail';
import { DeliveryManagementPage } from './ProductionPlanning/DeliveryManagementPage/DeliveryManagementPage';
import { MaterialAllocationsResultPage } from './ProductionPlanning/MaterialAllocationsResultPage/MaterialAllocationsResultPage';
import { DeletedDrawingCodePage } from './Drawing/DeletedDrawingCodePage/DeletedDrawingCodePage';
import { TargetNumberDispatchedSearchPage } from './SalesAndDistribution/TargetNumberPage/DispatchedSearchPage';
import { TargetRuleManagementPage } from './SalesAndDistribution/TargetNumberPage/TargetRuleManagementPage';
import { TargetRuleCreatePage } from './SalesAndDistribution/TargetNumberPage/TargetRuleCreatePage';
import { TargetNumberDispatcherPage } from './SalesAndDistribution/TargetNumberPage/TargetNumberDispatcherPage';
import { WaitStorageInPage } from './ProductionPlanning/WaitStorageInPage/WaitStorageInPage';
import { WorkReportBIPage } from './ProductionPlanning/WorkReportBI/WorkReportBI';
import StrikePriceReport from './StrikePrice/StrikePriceReport';
import { OutsourcingOrderPage } from './ProductionPlanning/OutsourcingOrderPage/OutsourcingOrderPage';
import { OutsourcingOrderDetailPage } from './ProductionPlanning/OutsourcingOrderDetailPage/OutsourcingOrderDetailPage';
import { TradeConditionPage } from './CustomerManagement/TradeConditionPage/TradeConditionPage';
import { StockBindOrder } from './Material/StockBindOrder/StockBindOrderPage';
import { MaterialIdUpdatePage } from './Material/MaterialIdUpdatePage/MaterialIdUpdatePage';
import { ToBeConfirmPage } from './Transportation/ToBeConfirmPage/ToBeConfirmPage';
import { ProductionTrackingPage } from './ProductionPlanning/ProductionTrackingPage/ProductionTrackingPage';
import { BillWeightRefPage } from './SalesAndDistribution/BillWeightRefPage/BillWeightRefPage';
import { SendForInspectionPage } from './ProductionPlanning/SubmissionResults/SendForInspection/SendForInspectionPage';
import { SubmissionResultsByInventoryPage } from './ProductionPlanning/SubmissionResults/SubmissionResultsByInventory/SubmissionResultsByInventoryPage';
import { SubmissionResultsPage } from './ProductionPlanning/SubmissionResults/SubmissionResultsPage/SubmissionResultsPage';
import { CopyAnswersByMaterialBatchPage } from './ProductionPlanning/SubmissionResults/CopyAnswersByMaterialBatch/CopyAnswersByMaterialBatch';
import { MiscellaneousManagePage } from './Material/MiscellaneousManagePage/MiscellaneousManagePage';
import { IncomingQualityControlPage } from './Qualifications/IncomingQualityControl/IncomingQualityControlPage';
import { IncomingQualityControlUserViewPage } from './Qualifications/IncomingQualityControlUserView/IncomingQualityControlUserViewPage';
import { IncomingQualityControlPurchaseViewPage } from './Qualifications/IncomingQualityControlPurchaseView/IncomingQualityControlPurchaseViewPage';
import { OutsourcePurchaseOrderPage } from './ProductionPlanning/OutsourcePurchaseOrderPage/OutsourcePurchaseOrderPage';
import { OutsourcePurchaseOrderDetailPage } from './ProductionPlanning/OutsourcePurchaseOrderPage/OutsourcePurchaseOrderDetailPage';
import { ExchangeMaterialsPage } from './ProductionPlanning/ExchangeMaterialPage/ExchangeMaterialsPage';
import { GlobalProvider } from './GlobalProvider/GlobalProvider';
import { MaterialSupplyToOutsourcingProcessOrderPage } from './Warehouse/MaterialSupplyToWorkOrderPage/MaterialSupplyToOutsourcingProcessOrderPage';
import { CPIIframe } from './OrderManagement/CPIIframe/CPIIframe';
import { ProductionFormEditorPage } from './ProductionPlanning/ProductionFormEditor/ProductionFormEditorPage';
import { InLineSPCAbnormalReportPage } from './ProductionPlanning/InLineSPCAbnormalReport/InLineSPCAbnormalReportPage';
import { RecipeImportPage } from './ProductionPlanning/RecipeImportsPage/RecipeImportsPage';
import { InventoryChange } from './Warehouse/InventoryChange/InventoryChange';
import { ReturnPurchaseOrderPage } from './Warehouse/ReturnPurchaseOrderPage/ReturnPurchaseOrderPage';
import { ExchangeMaterialsDetailPage } from './ProductionPlanning/ExchangeMaterialsDetailPage/ExchangeMaterialsDetailPage';
import { VirtualOutAndInPage } from './VirtualOutAndIn/VirtualOutAndInPage';
import { VirtualOutAndInBindingPage } from './VirtualOutAndIn/VirtualOutAndInBindingPage';
import { FixedAssetsAndMiscStorageInPage } from './Material/MiscellaneousStorageInPage/FixedAssetAndMiscStorageInPage';
import { FixedAssetStorageInDetailPage } from './Material/MiscellaneousStorageInPage/FixedAssetStorageInDetailPage';
import { VirtualOutDetailPage } from './Warehouse/MaterialSupplyPage/VirtualOutDetailPage';
import { MaterialPropertiesMaintainPage } from './Material/MaterialPropertiesMaintainPage/MaterialPropertiesMaintainPage';
import { ResetPasswordPage } from './Auth/ResetPasswordPage/ResetPasswordPage';

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<GlobalProvider />}>
          <Route path="*" element={<div />} />
          <Route path="auth">
            <Route index path="*" element={<Navigate to="login" replace />} />
            <Route path="shipment">
              <Route index element={<ShipmentPackingPage />} />
            </Route>
            <Route path="login" element={<LoginPage />} />
            <Route path="reset-password" element={<ResetPasswordPage />} />
            <Route path="tenant" element={<TenantPage />} />
            <Route path="role" element={<RolePage />} />
          </Route>
          <Route element={<MainBoard />}>
            <Route path="equipments">
              <Route index element={<EquipmentListPage />} />
              <Route
                path="maintenance"
                element={<EquipmentMaintenancePage />}
              />
              <Route path="grafana" element={<GrafanaPage />} />
            </Route>
            <Route path="facilities">
              <Route path="electric" element={<FacilitiesElectricPage />} />
            </Route>
            <Route path="human-resources">
              <Route path="emp" element={<HumanResourceEmpPage />} />
              <Route
                path="department-information"
                element={<HumanResourceDepartmentInformationPage />}
              />
              <Route
                path="emp-details"
                element={<HumanResourceEmpDetailPage />}
              />
              <Route path="bonus" element={<HumanResourceBonusPage />} />
              <Route path="bu" element={<HumanResourceBUTeamPage />} />
              <Route path="bu-manage" element={<HumanResourceBUManagePage />} />
              <Route path="reports" element={<HumanResourceReportPage />} />
              <Route
                path="reports-detail"
                element={<HumanResourceReportDetailsPage />}
              />
              <Route path="members" element={<HumanResourceMembersPage />} />
              <Route path="chart" element={<HumanResourceChartPage />} />
            </Route>
            <Route path="customer-management">
              <Route path="trade-condition" element={<TradeConditionPage />} />
            </Route>
            <Route path="order">
              <Route path="manage" element={<OrderManagementPage />} />
              <Route
                path="order-predict-search"
                element={<OrderPredictSearchPage />}
              />
              <Route
                path="order-predict-add"
                element={<OrderPredictUpsertPage />}
              />
              <Route
                path="order-predict-edit/:orderId"
                element={<OrderPredictUpsertPage />}
              />
              <Route path="cpi" element={<CPIIframe />} />
              <Route
                path="virtual-out-and-in"
                element={<VirtualOutAndInPage />}
              />
              <Route
                path="virtual-out-and-in/:deliveryOrderId/binding"
                element={<VirtualOutAndInBindingPage />}
              />
            </Route>
            <Route path="strike-price">
              <Route path="view/:id" element={<StrikePriceUpsert />} />
              <Route path="validate/:id" element={<StrikePriceUpsert />} />
              <Route path="edit/:id" element={<StrikePriceUpsert />} />
              <Route path="match/:id" element={<StrikePriceUpsert />} />
              <Route path="search" element={<StrikePriceSearch />} />
              <Route path="approve" element={<StrikePriceSearch />} />
              <Route path="report" element={<StrikePriceReport />} />
              <Route path="add" element={<StrikePriceUpsert />} />
            </Route>
            <Route path="sales-distribution">
              <Route
                index
                path="*"
                element={<Navigate to="main-file" replace />}
              />
              <Route path="main-file" element={<MainFilePage />} />
              <Route path="sales-return">
                <Route index element={<SalesReturnPage />}></Route>
                <Route path=":id" element={<SalesReturnDetailPage />} />
              </Route>
              <Route path="packaging" element={<PackagingPage />} />
              <Route path="order-marks" element={<OrderMarksPage />} />
              <Route path="estimate" element={<PackagingEstimate />} />
              <Route path="shipment">
                <Route index element={<ShipmentPackingPage />} />
                <Route
                  path=":shipmentId/box-merge"
                  element={<ShipmentBoxMergePage />}
                />
                <Route
                  path=":shipmentId"
                  element={<ShipmentPackingDetailPage />}
                />
                <Route
                  path=":shipmentId/pdfPrint"
                  element={<ShipmentPackingPrintPage />}
                />
              </Route>
              <Route path="hub-delivery-order">
                <Route index element={<HubDeliveryOrderPage />} />
              </Route>
              <Route path="hub-receiving-order">
                <Route index element={<HubReceivingOrderPage />} />
              </Route>
              <Route path="hub-warehouse">
                <Route index element={<HubWarehousePage />} />
              </Route>
              <Route path="bill-weight-ref">
                <Route index element={<BillWeightRefPage />} />
              </Route>
            </Route>
            <Route path="warehouse">
              <Route
                path="return-purchase-order"
                element={<ReturnPurchaseOrderPage />}
              />
              <Route path="material-collect">
                <Route index element={<MaterialCollectPage />} />
                <Route
                  path="create"
                  element={<CreateMaterialRegisterOrderPage />}
                />
              </Route>
              <Route path="material-supply">
                <Route index element={<MaterialSupplyPage />} />
                <Route path="production-work-order">
                  <Route
                    path=":workOrderId"
                    element={<MaterialSupplyToWorkOrderPage />}
                  />
                </Route>
                <Route path="outsourcing-work-order">
                  <Route
                    path=":outsourcingOrderId"
                    element={<MaterialSupplyToOutsourcingOrderPage />}
                  />
                </Route>
                <Route path="outsourcing-process-order">
                  <Route
                    path=":outsourcingOrderId"
                    element={<MaterialSupplyToOutsourcingProcessOrderPage />}
                  />
                </Route>
                <Route path="virtual-delivery-order">
                  <Route
                    path=":virtualDeliveryOrderId"
                    element={<VirtualOutDetailPage />}
                  />
                </Route>
                <Route path="delivery-order">
                  <Route path=":id" element={<DeliveryOrderDetailPage />} />
                </Route>
                <Route path="returned-order">
                  <Route
                    path=":returnedOrderId"
                    element={<MaterialSupplyToReturnedOrderPage />}
                  />
                </Route>
              </Route>
              <Route path="storage-in" element={<GeneratedStorageIn />} />
              <Route path="material-shift" element={<MaterialShiftPage />} />
              <Route path="stock-bind-order" element={<StockBindOrder />} />
              <Route
                path={toDashConcatCase(WarehouseModule.MiscellaneousStorageIn)}
              >
                <Route index element={<FixedAssetsAndMiscStorageInPage />} />
                <Route
                  path="misc/:orderId"
                  element={<MiscellaneousStorageInDetailPage />}
                />
                <Route
                  path="fixed-asset/:orderId"
                  element={<FixedAssetStorageInDetailPage />}
                />
              </Route>
              <Route
                path="miscellaneous-manage"
                element={<MiscellaneousManagePage />}
              />
              <Route
                path="material-scrapping"
                element={<MaterialScrappingPage />}
              />
              <Route path="material-return-order">
                <Route
                  path="*"
                  element={<Navigate to="storage-purchase-order" replace />}
                />
                <Route
                  path="storage-purchase-order"
                  element={<MaterialReturnOrderPage />}
                />
                <Route
                  path="storage-purchase-order/:orderId"
                  element={<MaterialReturnOrderDetailPage />}
                />
                <Route
                  path="misc-purchase-order"
                  element={<MaterialReturnOrderPage />}
                />
                <Route
                  path="misc-purchase-order/:orderId"
                  element={<MaterialReturnOrderDetailPage />}
                />
                <Route
                  path="outsource-purchase-order"
                  element={<MaterialReturnOrderPage />}
                />
                <Route
                  path="outsource-purchase-order/:orderId"
                  element={<MaterialReturnOrderDetailPage />}
                />
                <Route
                  path="customer-order"
                  element={<MaterialReturnOrderPage />}
                />
                <Route
                  path="customer-order/:orderId"
                  element={<MaterialReturnOrderDetailPage />}
                />
              </Route>
              {/* <Route
                path="property-maintain"
                element={<MaterialPropertiesMaintainPage />}
              >
                <Route
                  index
                  element={<Navigate to="?tab=material_group" replace />}
                />
              </Route> */}
              <Route
                path="operation-history"
                element={<OperationHistoryPage />}
              />
              <Route
                path="miscellaneous-dispatching"
                element={<MiscellaneousDispatchAndReturnPage />}
              />
              <Route
                path={'loader-management'}
                element={<TempLoaderManagementPage />}
              />
              {/* <Route
                path={'loader-management/:loaderId'}
                element={<LoaderManagementDetailPage />}
              /> */}
              <Route
                path={'loader-management/create'}
                element={<CreateLoaderPage />}
              />
              <Route
                path={'material-receipt-check'}
                element={<MaterialReceiptCheckPage />}
              />
              <Route
                path="material-transfer"
                element={<MaterialTransferPage />}
              />
              <Route path="map" element={<WarehouseMapPage />} />
              <Route path="tempList" element={<WarehouseTempListPage />} />
              <Route path="return-material" element={<ReturnMaterialPage />} />
              <Route
                path="return-material/:id"
                element={<ReturnMaterialDetailPage />}
              />
              <Route path="inventory" element={<Inventory />} />
              <Route
                path="inventory/:inventoryId"
                element={<InventoryDetail />}
              />
              <Route path="stock-change" element={<InventoryChange />} />
            </Route>
            <Route path="material-code">
              <Route
                path="configuration"
                element={<MaterialCodeConfiguration />}
              />
              <Route
                path="configuration/:id"
                element={<MaterialCodeConfigurationDetailPage />}
              />
              <Route
                path="configuration/create"
                element={<MaterialCodeConfigurationCreatePage />}
              />
              <Route path="transform" element={<MaterialCodeTransform />} />
              <Route
                path="transform/:id"
                element={<MaterialCodeTransformDetailPage />}
              />
              <Route
                path="material-mapping"
                element={<MaterialMappingPage />}
              />
              <Route
                path="property-maintain"
                element={<MaterialPropertiesMaintainPage />}
              />
              <Route
                path="misc-material-inventory"
                element={<MiscMaterialInventoryPage />}
              />
              <Route
                path="material-id-update"
                element={<MaterialIdUpdatePage />}
              />
              <Route path="viewer" element={<MaterialViewerPage />} />
              <Route path="ingredientserach" element={<IngredientSearch />} />
            </Route>
            <Route path="incoming-inspect">
              <Route
                index
                path=""
                element={<Navigate to="purchase-order" replace />}
              />
              {/* <Route
                path="purchase-order"
                element={<IncomingInspectOperationPage />}
              /> */}
              <Route
                path=":orderType"
                element={<IncomingInspectOperationPage />}
              />
              <Route
                path=":orderType/:orderId"
                element={<IncomingInspectDetailPage />}
              />
              {/* <Route
                path="return-order"
                element={<IncomingInspectOperationPage />}
              />
              <Route
                path="return-order/:orderId"
                element={<IncomingInspectDetailPage />}
              /> */}
            </Route>
            <Route path="material-enquiry">
              <Route
                index
                path="*"
                element={<Navigate to="material-view" replace />}
              />
              <Route path="material-view" element={<CostWarehousePage />} />
              <Route path="warehouse-view" element={<CostWarehousePage />} />
            </Route>
            <Route path="warehouse-enquiry">
              <Route
                index
                path="*"
                element={<Navigate to="material-view" replace />}
              />
              <Route path="material-view" element={<WarehouseEnquiry />} />
              <Route path="warehouse-view" element={<WarehouseEnquiry />} />
            </Route>
            <Route path="warehouse">
              <Route
                path="material-mapping"
                element={<MaterialMappingPage />}
              />
              <Route
                path="material-transfer"
                element={<MaterialTransferPage />}
              />
              <Route path="map" element={<WarehouseMapPage />} />
            </Route>
            <Route path="customer-supply">
              <Route
                index
                path="*"
                element={<Navigate to="supply-view" replace />}
              />
              <Route
                path="supply-view"
                element={<CusTomerSupplyWarehousePage />}
              />
              <Route
                path="warehouse-view"
                element={<CusTomerSupplyWarehousePage />}
              />
            </Route>
            <Route path="drawings">
              <Route index path="*" element={<DrawingPage />} />
              <Route path=":newDrawingCode" element={<DrawingDetailPage />} />
              <Route
                path=":newDrawingCode/update"
                element={<EditDrawingPage />}
              />

              <Route path="tem">
                <Route index element={<TEMDrawingPage />} />
                <Route
                  path=":newDrawingCode"
                  element={<TEMDrawingDetailPage />}
                />
                <Route
                  path=":newDrawingCode/update"
                  element={<TEMEditDrawingPage />}
                />
              </Route>
              <Route path="fcc">
                <Route index element={<FCCDrawingPage />} />
                <Route
                  path=":newDrawingCode"
                  element={<FCCDrawingDetailPage />}
                />
                <Route
                  path=":newDrawingCode/update"
                  element={<FCCEditDrawingPage />}
                />
              </Route>
              <Route path="pending" element={<PendingDrawingsPage />} />
              <Route path="create" element={<CreateDrawingPage />} />
              <Route
                path="attribute-code-mapping-table"
                element={<AttributeCodeMappingPage />}
              />
              <Route
                path="deleted-drawing-code"
                element={<DeletedDrawingCodePage />}
              />
            </Route>
            <Route path="production-planning">
              <Route
                path="delivery-management"
                element={<DeliveryManagementPage />}
              />
              <Route
                path="outsourcing-orders"
                element={<OutsourcingOrderPage />}
              />
              <Route
                path="outsourcing-orders/:orderId"
                element={<OutsourcingOrderDetailPage />}
              />
              <Route
                path="material-allocations"
                element={<MaterialAllocationsPage />}
              />
              <Route
                path="material-allocations-result"
                element={<MaterialAllocationsResultPage />}
              />
              <Route
                path="work-order-management"
                element={<WorkOrderManagementPage />}
              />
              <Route
                path="exchange-materials"
                element={<ExchangeMaterialsPage />}
              />
              <Route
                path="exchange-materials/:orderId"
                element={<ExchangeMaterialsDetailPage />}
              />
              <Route
                path="work-order-management/:orderId"
                element={<WorkOrderManagementDetailPage />}
              />
              <Route
                path="outsource-purchase-order-management"
                element={<OutsourcePurchaseOrderPage />}
              />
              <Route
                path="outsource-purchase-order-management/:orderId"
                element={<OutsourcePurchaseOrderDetailPage />}
              />
              <Route path="dispatch-orders" element={<DispatchOrderPage />} />
              {/* <Route
                          path="dispatch-order/:tab"
                          element={<DispatchOrderPage />}
                        />
                        <Route
                          path="dispatch-order/:tab/:orderId"
                          element={<DispatchOrderDetailPage />}
                        /> */}
              {/* <Route path="work-orders" element={<WorkOrderRecipePage />} /> */}
              <Route path="work-orders" element={<WorkOrderPage />} />
              <Route path="routings" element={<RoutingPage />} />
              <Route path="recipes" element={<RecipePage />} />
              <Route path="recipes/imports" element={<RecipeImportPage />} />
              <Route path="recipes/table" element={<RecipeTablePage />} />
              <Route path="form-templates" element={<FormTemplatePage />} />
              <Route
                path="usage-prediction"
                element={<UsagePredictionPage />}
              />
              <Route path="work-report-bi" element={<WorkReportBIPage />} />
              <Route
                path="routings-imported"
                element={<RoutingPage imported />}
              />
              <Route
                path="packaging-station"
                element={<PackagingStationPage />}
              />
              <Route
                path="packaging-station/:orderId"
                element={<PackagingStationDetailPage />}
              />
              <Route
                path="normal-manufacturing"
                element={<NormalManufacturingPage />}
              />
              <Route
                path="normal-manufacturing/:tab"
                element={<NormalManufacturingPage />}
              />
              <Route
                path="recycle-manufacturing"
                element={<NormalManufacturingPage />}
              />
              <Route
                path="recycle-manufacturing/:tab"
                element={<NormalManufacturingPage />}
              />
              <Route
                path="production-forms/edit"
                element={<ProductionFormEditorPage />}
              />
              <Route path="wait-storage-list" element={<WaitStorageInPage />} />
              <Route
                path="production-tracking"
                element={<ProductionTrackingPage />}
              />
              <Route
                path="in-line-spc-abnormal-report"
                element={<InLineSPCAbnormalReportPage />}
              />
            </Route>

            <Route path="qualifications">
              <Route
                path="1100/reports"
                element={<QualificationReportPage factoryId="1100" />}
              />
              <Route
                path="1200/reports"
                element={<QualificationReportPage factoryId="1200" />}
              />
              <Route path="incoming" element={<IncomingQualityControlPage />} />
              <Route
                path="incoming/user-view"
                element={<IncomingQualityControlUserViewPage />}
              />
              <Route
                path="incoming/purchase-view"
                element={<IncomingQualityControlPurchaseViewPage />}
              />
              <Route
                path="send-for-inspection"
                element={<SendForInspectionPage />}
              />
              <Route
                path="submission-results/inventory"
                element={<SubmissionResultsByInventoryPage />}
              />
              <Route
                path="submission-results"
                element={<SubmissionResultsPage />}
              />
              <Route
                path="submission-results/copy"
                element={<CopyAnswersByMaterialBatchPage />}
              />
            </Route>
            <Route path="print-management">
              <Route path="label" element={<LabelPage />} />
              <Route
                path="work-order"
                element={<PrintManagementWorkOrderPage />}
              />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="equipments" replace />} />
          <Route path="transportation">
            <Route path="requirement">
              <Route path="new" element={<RequirementPage />} />
              <Route path=":id" element={<RequirementPage />} />
            </Route>
            <Route
              path="requirements-management"
              element={<RequirementsManagementPage />}
            />
            <Route
              path="requirements-management/print"
              element={<PrintPage />}
            />
            <Route path="management" element={<ManagementPage />} />
            <Route path="to-be-confirm-list" element={<ToBeConfirmPage />} />
            <Route path="workorderprint" element={<WorkOrderPrintPage />} />
            <Route path="billofmaterial" element={<BillOfMaterialPage />} />
          </Route>
          <Route path="target-number">
            <Route
              path="number-dispatcher"
              element={<TargetNumberDispatcherPage />}
            />
            <Route
              path="dispatched-search"
              element={<TargetNumberDispatchedSearchPage />}
            />
            <Route path="number-rules">
              <Route path="management" element={<TargetRuleManagementPage />} />
              <Route path="create" element={<TargetRuleCreatePage />} />
              <Route
                path="edit/:NumberRuleId"
                element={<TargetRuleCreatePage />}
              />
              <Route
                path="view-only/:NumberRuleId"
                element={<TargetRuleCreatePage />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
