import {
  Button,
  Modal,
  Table,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
  Icon,
  Tooltip,
  Popper,
  MenuDivider,
} from '@mezzanine-ui/react';
import { Modals } from './Modal.enum';
import { MznPaginationTable, useTargetModal } from '@solar/templates';
import { useForm, useWatch } from 'react-hook-form';
import {
  AutoCompleteField,
  AutoCompleteMultiField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Descriptions, Divider, Row, Space } from 'antd';
import styles from './add-materials-modal.module.scss';
import {
  FetchingInventoryStockTypes,
  FormAnswer,
  InventoryByMaterialSearchType,
  InventoryItem,
  ManualData,
  NextPagination,
  getNextParams,
  useBatchIdsByMaterialId,
  useGetComponentCodes,
  useGetInventoryByMaterial,
  useGetLoaders,
  useMaterials,
} from '@solar/data';
import { TableRowSelection } from '@mezzanine-ui/core/table';
import { InfoCircleFilledIcon } from '@mezzanine-ui/icons';

type AddMaterialsModalProps = {
  appending: boolean;
  manualData: ManualData[];
  onSubmit: (selectedRecords: string[]) => void;
};

export function AddMaterialsModal({
  appending,
  manualData,
  onSubmit,
}: AddMaterialsModalProps) {
  const methods = useForm();
  const { open, data, closeModal } = useTargetModal<{}>(Modals.ADD_MATERIALS);
  const [selectedRecords, setSelectedRecords] = useState<string[]>([]);
  const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>(null);
  const [inventoryInPopper, setInventoryInPopper] = useState<InventoryItem>();
  const { componentCodes, refetchGetComponentCodes } = useGetComponentCodes();
  const { loaders, refetchGetLoaders } = useGetLoaders();
  const { materials, setParams: refetchGetMaterials } = useMaterials();
  const {
    inventoryByMaterial,
    isLoading,
    pageInfo,
    mutateGetInventoryByMaterial,
  } = useGetInventoryByMaterial({
    type: InventoryByMaterialSearchType.MATERIAL_ID_OR_MATERIAL_NAME,
    stockTypes: [FetchingInventoryStockTypes.AVAILABLE],
    withElementRatio: true,
  });

  const watchedMaterial = useWatch({
    control: methods.control,
    name: 'materialId',
  });

  const { data: batches, setParams: mutateBatchIdsByMaterialParams } =
    useBatchIdsByMaterialId({
      materialId: watchedMaterial?.id ?? null,
    });

  const refetchGetInventoryByMaterialWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateGetInventoryByMaterial({
        type: formState?.type?.id,
        searchTerms: [formState?.searchTerm],
        offset,
        limit,
        locationIds: formState?.areaIds?.map((option: any) => option?.id) ?? [],
        materialIds: formState?.materialId?.id
          ? [formState?.materialId?.id]
          : undefined,
        materialBatchIds: formState?.batchId?.id
          ? [formState?.batchId?.id]
          : undefined,
        loaderIds: formState?.loaderIds?.map((option: any) => option?.id) ?? [],
        componentCodes:
          formState?.componentCodes?.map((option: any) => option?.id) ?? [],
        stockTypes: [FetchingInventoryStockTypes.AVAILABLE],
        withElementRatio: true,
      });
    },
    [methods, mutateGetInventoryByMaterial, pageInfo]
  );

  useEffect(() => {
    if (open) {
      // setSelectedRecords(manualData?.map((item) => item?.id));
      setSelectedRecords(
        manualData?.map(
          (item) => item?.batchStoredLoaderRecordId?.toString() ?? ''
        )
      );
    }
  }, [open, data, manualData]);

  const onClose = () => {
    closeModal();
  };

  const onSave = () => {
    try {
      onSubmit(selectedRecords);
    } catch (e) {
      console.log(e);
    }
  };

  const dataSource = useMemo(
    () =>
      inventoryByMaterial?.map((m) =>
        Object.assign({ id: m?.batchStoredLoaderRecordId?.toString() }, m)
      ) ?? [],
    [inventoryByMaterial]
  );

  const rowSelection = useMemo<TableRowSelection>(() => {
    return {
      selectedRowKey: selectedRecords,
      onChange: (keys) => setSelectedRecords(keys),
      disabledRowKeys: dataSource
        ?.filter(
          (inventory) =>
            !['G', 'KG'].includes(inventory?.materialStockUnit?.toUpperCase())
        )
        ?.map(
          (inventory) => inventory?.batchStoredLoaderRecordId?.toString() ?? ''
        ),
    };
  }, [selectedRecords, dataSource]);

  return (
    <Modal style={{ width: '1200px' }} open={open} onClose={onClose}>
      <ModalHeader>查詢配料</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <AutoCompleteField
                className={styles.field}
                registerName="materialId"
                label="料號"
                onSearch={(searchTerm) =>
                  refetchGetMaterials({ searchTerm, searchType: 'ID' })
                }
                options={
                  materials?.map((material) => ({
                    id: material?.id,
                    name: material?.id,
                  })) ?? []
                }
              />
            </Col>
            <Col span={12}>
              <AutoCompleteField
                className={styles.field}
                label="批號"
                disabled={!watchedMaterial}
                registerName="batchId"
                onInput={({ target }) => {
                  mutateBatchIdsByMaterialParams((prev) => ({
                    ...prev,
                    searchTerm: (target as HTMLInputElement).value,
                  }));
                }}
                options={
                  batches.map((row) => ({
                    id: row.batchId,
                    name: row.batchId,
                  })) ?? []
                }
              />
            </Col>
            <Col span={8}>
              <AutoCompleteMultiField
                className={styles.field}
                registerName="loaderIds"
                label="載具"
                onSearch={(searchTerm) => refetchGetLoaders({ searchTerm })}
                options={loaders?.map((loader) => ({
                  id: loader?.id?.toString() ?? '',
                  name: loader?.id?.toString() ?? '',
                }))}
              />
            </Col>
            <Col span={8}>
              <AutoCompleteMultiField
                className={styles.field}
                registerName="componentCodes"
                label="成分代碼"
                onSearch={(searchTerm) =>
                  refetchGetComponentCodes({ searchTerm })
                }
                options={componentCodes?.map((code) => ({
                  id: code,
                  name: code,
                }))}
              />
            </Col>
            <Col span={8}>
              <SelectField
                className={styles.field}
                registerName="areaIds"
                mode="multiple"
                label="儲位"
                options={[
                  { id: '1100A1H', name: '科工廠 A 棟 1 樓 H 區' },
                  { id: '1100A2H', name: '科工廠 A 棟 2 樓 H 區' },
                  { id: '1100A3B', name: '科工廠 A 棟 3 樓 B 區' },
                  { id: '1100A4HB', name: '科工廠 A 棟 4 樓 H 區 B' },
                  { id: '1100A4HG', name: '科工廠 A 棟 4 樓 H 區 G' },
                ]}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ margin: '12px 0' }}>
            <Button
              style={{ width: 120 }}
              onClick={() =>
                refetchGetInventoryByMaterialWithPagination({ nextPage: 1 })
              }
            >
              查詢
            </Button>
          </Row>
          <Divider orientation="left">可用料號列表</Divider>
          <MznPaginationTable
            loading={isLoading}
            fetchData={refetchGetInventoryByMaterialWithPagination}
            pageInfo={pageInfo ?? {}}
            dataSource={dataSource}
            rowSelection={rowSelection}
            scroll={{ x: 1500 }}
            columns={[
              {
                title: '批號',
                dataIndex: 'batchId',
              },
              {
                title: '料號',
                dataIndex: 'materialId',
              },
              {
                title: '品名',
                dataIndex: 'materialDescription',
              },
              {
                title: '基礎計量單位',
                dataIndex: 'materialStockUnit',
              },
              {
                title: '載具',
                dataIndex: 'loaderId',
              },
              // {
              //   title: '成分代碼',
              //   render: (source: InventoryItem) => {
              //     return (
              //       <Tooltip
              //         title={
              //           <ul>
              //             <Typography variant="h4">成份比例</Typography>
              //             {source?.elementRatioAnswers?.map((formAnswer) => (
              //               <li key={formAnswer?.id}>
              //                 <Space>
              //                   <Typography variant="h5">
              //                     {formAnswer?.form?.title ?? ''}
              //                   </Typography>
              //                   <Typography variant="h5">
              //                     {formAnswer?.answer ?? ''}
              //                   </Typography>
              //                   <Typography variant="h5">
              //                     {formAnswer?.form?.suffix ?? ''}
              //                   </Typography>
              //                 </Space>
              //               </li>
              //             ))}
              //           </ul>
              //         }
              //       >
              //         {({ onMouseEnter, onMouseLeave }) => (
              //           <div
              //             style={{
              //               color: 'var(--mzn-color-primary)',
              //               cursor: 'pointer',
              //             }}
              //             onMouseEnter={onMouseEnter}
              //             onMouseLeave={onMouseLeave}
              //           >
              //             {source?.componentCode ?? '-'}
              //           </div>
              //         )}
              //       </Tooltip>
              //     );
              //   },
              // },
              {
                title: '儲位',
                dataIndex: 'shelfId',
              },
              {
                title: '基礎計量數量',
                dataIndex: 'quantity',
              },
              {
                title: '備註',
                dataIndex: 'materialSubBatchRemark',
              },
            ]}
            expandable={{
              rowExpandable: (record: InventoryItem) => !!record?.componentCode,
              expandedRowRender: (record: InventoryItem) => (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Descriptions
                    bordered
                    column={1}
                    labelStyle={{ width: 200 }}
                    contentStyle={{ backgroundColor: '#fff' }}
                  >
                    <Descriptions.Item label="成分代碼">
                      {record?.componentCode}
                    </Descriptions.Item>
                    <Descriptions.Item label="標準成分比例">
                      <Space
                        direction="vertical"
                        split={<Divider style={{ margin: 0 }} />}
                      >
                        {record?.componentItemNumber
                          ?.split('/')
                          ?.map((item, index) => (
                            <div
                              key={item}
                              style={{
                                width: 300,
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <span>{item}</span>
                              <span>{record?.wtRate?.split('/')?.[index]}</span>
                            </div>
                          ))}
                      </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="實際成分比例">
                      <Space
                        direction="vertical"
                        split={<Divider style={{ margin: 0 }} />}
                      >
                        {record?.elementRatioAnswers?.map((formAnswer) => (
                          <div
                            key={formAnswer?.id}
                            style={{
                              width: 300,
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>{formAnswer?.form?.title ?? ''}</span>
                            <span>
                              {formAnswer?.answer ?? ''}{' '}
                              {formAnswer?.form?.suffix ?? ''}
                            </span>
                          </div>
                        ))}
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                </Space>
              ),
            }}
          />
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>取消</Button>
          </Col>
          <Col>
            <Button variant="contained" loading={appending} onClick={onSave}>
              加入
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
