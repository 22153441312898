import {
  ConfigProvider,
  Divider,
  Table,
  Typography,
  Col,
  Row,
  Descriptions,
  Spin,
  Space,
} from 'antd';
import styles from './BillOfMaterial.module.scss';
import { forwardRef, useMemo } from 'react';
import { Decimal } from 'decimal.js';
import dayjs from 'dayjs';
import {
  API_NAMESPACE,
  useMaterialRequestOrder,
  useWorkOrder,
} from '@solar/data';
import { DataMatrix } from '../../Templates/DataMatrix';
import useSWR from 'swr';
import { Recipe } from '../../ProductionPlanning/RecipePage/typing';

type BillOfMaterialPageProps = {
  billOfMaterial?: any;
  materialRequestId?: string | null;
};

export const BillOfMaterialPage = forwardRef<
  HTMLDivElement,
  BillOfMaterialPageProps
>(({ materialRequestId }, ref) => {
  const { data: materialRequestOrder, isLoading } = useMaterialRequestOrder({
    orderId: materialRequestId ?? undefined,
  });
  const { order: workOrder } = useWorkOrder({
    orderId: materialRequestOrder?.workOrderId,
  });

  const { data: recipeData } = useSWR<Recipe[]>(
    materialRequestOrder?.recipeId
      ? [
          `/recipes?recipeId=${materialRequestOrder?.recipeId}&recipeVersion=${materialRequestOrder?.recipeVersion}`,
          { namespace: API_NAMESPACE.PP },
        ]
      : undefined
  );

  const headers = {
    workOrderId: workOrder?.id,
    workOrderType: workOrder?.orderTypeName,
    requestOrderId: materialRequestOrder?.id,
  };

  const expectedProductionColumns = [
    {
      title: '產出類型',
      width: 100,
      render: (source: any) => {
        switch (source?.productionType) {
          case 'EXPECTED_MAIN_PRODUCT':
            return '主產品';
          case 'EXPECTED_SECONDARY_PRODUCT':
            return '副產品';
          case 'EXPECTED_JOINT_PRODUCT':
            return '聯產品';
          default:
            return '';
        }
      },
    },
    { title: '料號', dataIndex: 'materialId', width: 80 },
    {
      title: '批號',
      dataIndex: 'materialSapBatchId',
      width: 250,
      render: (value: string, record: any) =>
        `${value}-${record?.materialBatchId}`,
    },
    { title: '數量', dataIndex: 'amount', width: 100 },
    {
      title: '條碼',
      dataIndex: 'materialBarcode',
      width: 100,
      render: (barcode: string, record: any) =>
        record?.id && barcode ? (
          <DataMatrix
            canvasId={`${record?.id}-material-barcode`}
            text={barcode}
            width={5}
            height={5}
          />
        ) : null,
    },
  ];

  const ingredientListColumns = [
    { title: '料號', dataIndex: ['material', 'id'], width: 80 },
    { title: '單位', dataIndex: ['material', 'materialStockUnit'], width: 50 },
    { title: '品名', dataIndex: ['material', 'description'] },
    { title: '儲位', dataIndex: ['loader', 'shelfId'], width: 100 },
    {
      title: '批號',
      dataIndex: 'materialSapBatchId',
      width: 250,
      render: (value: string, record: any) =>
        `${value}-${record?.materialBatchId}`,
    },
    {
      title: '基礎計量單位數量',
      width: 100,
      render: (source: any) =>
        `${source?.expectedMaterialStockUnitQuantity ?? '-'} ${
          source?.material?.materialStockUnit ?? '-'
        }`,
    },
    {
      title: '管理單位數量',
      width: 100,
      render: (source: any) =>
        `${source?.expectedMaterialInputUnitQuantity ?? '-'} ${
          source?.materialInputUnit ?? '-'
        }`,
    },
    {
      title: '實際重量',
      dataIndex: 'actualMaterialWeightUnitQuantity',
      width: 100,
    },
  ];

  const totalSpecQuantity =
    materialRequestOrder?.materialRequestOrderSpecs?.reduce(
      (sum, spec) =>
        Decimal?.add(
          sum,
          spec?.expectedMaterialStockUnitQuantity ?? '0'
        )?.toString(),
      '0'
    );

  const recipeRoutings = useMemo(() => {
    const recipe = recipeData?.[0];
    return recipe?.recipeRoutings
      .map((recipeRouting, recipeRoutingIndex) => {
        const baseSequence =
          recipe.recipeRoutings
            .slice(0, recipeRoutingIndex)
            .reduce(
              (sum, recipeRouting) =>
                sum + recipeRouting.routingGroup.routings.length,
              0
            ) * 10;

        return recipeRouting.routingGroup.routings.map((routing, index) =>
          index === 0
            ? recipeRouting.bomGroup.inputs.map((input) => ({
                id: `${recipe.id}-${routing.sequence + baseSequence}-${
                  input.inputMaterialId
                }`,
                recipeId: recipe.id,
                workOrderSequence: routing.sequence,
                sequence: routing.sequence + baseSequence,
                producedMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                producedMaterialId: recipeRouting.bomGroup.producedMaterialId,
                standardOutputUnit: recipeRouting.bomGroup.standardOutputUnit,
                standardOutputWeight:
                  recipeRouting.bomGroup.standardOutputWeight,
                inputMaterialId: input.inputMaterialId,
                inputMaterialFactoryId: input.factoryId,
                standardUnit: input.standardUnit,
                standardWeight: input.standardWeight,
                standardMachineMinutes: routing.standardMachineMinutes,
                standardManPowerMinutes: routing.standardManPowerMinutes,
                workCenter: routing.workCenter,
                processing: routing.processing,
                componentCode: recipe.finalDestinationMaterial.componentCode,
                drawingCode: recipe.finalDestinationMaterial.drawingCode,
                recipeCodePrefix:
                  recipe.finalDestinationMaterial.recipeCodePrefix,
                oldComponentCode:
                  recipe.finalDestinationMaterial.oldComponentCode,
                oldDrawingCode: recipe.finalDestinationMaterial.oldDrawingCode,
              }))
            : {
                id: `${recipe.id}-${routing.sequence + baseSequence}-${
                  recipeRouting.bomGroup.producedMaterialId
                }`,
                recipeId: recipe.id,
                workOrderSequence: routing.sequence,
                sequence: routing.sequence + baseSequence,
                producedMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                producedMaterialId: recipeRouting.bomGroup.producedMaterialId,
                standardOutputUnit: recipeRouting.bomGroup.standardOutputUnit,
                standardOutputWeight:
                  recipeRouting.bomGroup.standardOutputWeight,
                inputMaterialId: recipeRouting.bomGroup.producedMaterialId,
                inputMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                standardUnit: recipeRouting.bomGroup.standardOutputUnit,
                standardWeight: recipeRouting.bomGroup.standardOutputWeight,
                standardMachineMinutes: routing.standardMachineMinutes,
                standardManPowerMinutes: routing.standardManPowerMinutes,
                workCenter: routing.workCenter,
                processing: routing.processing,
                componentCode: recipe.finalDestinationMaterial.componentCode,
                drawingCode: recipe.finalDestinationMaterial.drawingCode,
                recipeCodePrefix:
                  recipe.finalDestinationMaterial.recipeCodePrefix,
                oldComponentCode:
                  recipe.finalDestinationMaterial.oldComponentCode,
                oldDrawingCode: recipe.finalDestinationMaterial.oldDrawingCode,
              }
        );
      })
      .flat()
      .flat();
  }, [recipeData]);

  return (
    // <Spin spinning={isLoading}>
    <Spin spinning={false}>
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 0,
            fontSize: 10,
          },
        }}
      >
        <div ref={ref} className={`${styles.wrapper} ${styles.landscape}`}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 24,
            }}
          >
            <Space>
              <img src="/assets/brand.png" alt="Solar" />
              <Typography.Title level={3}>
                光洋應用材料科技有限公司（配料表）
              </Typography.Title>
            </Space>
            {materialRequestOrder?.id && (
              <DataMatrix
                canvasId="barcode"
                text={materialRequestOrder?.id}
                width={10}
                height={10}
              />
            )}
          </div>
          <Descriptions
            bordered
            column={3}
            size="small"
            labelStyle={{ width: '4cm' }}
            contentStyle={{ width: '6cm' }}
          >
            <Descriptions.Item label="工單單號">
              {headers?.workOrderId ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="工單類別">
              {headers?.workOrderType ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="發料單號">
              {headers?.requestOrderId ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="預期領料日期">
              {materialRequestOrder?.expectedCompletedAt
                ? dayjs(materialRequestOrder?.expectedCompletedAt).format(
                    'YYYY-MM-DD'
                  )
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="生管指定完工日期">
              {materialRequestOrder?.productionExpectedFinishAt
                ? dayjs(
                    materialRequestOrder?.productionExpectedFinishAt
                  ).format('YYYY-MM-DD')
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="工單預期完成日">
              {workOrder?.expectedCompletedAt
                ? dayjs(workOrder?.expectedCompletedAt).format('YYYY-MM-DD')
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="發料單生產備註" span={3}>
              <Typography>{materialRequestOrder?.remark}</Typography>
            </Descriptions.Item>
            <Descriptions.Item label="銷售訂單備註" span={3}>
              <Typography>{workOrder?.salesOrderNote}</Typography>
            </Descriptions.Item>
            <Descriptions.Item label="工單備註" span={3}>
              <Typography>{workOrder?.remark}</Typography>
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">
            {recipeData?.[0]?.id} version {recipeData?.[0]?.version}
          </Divider>
          <Table
            bordered
            id="secondTable"
            size="middle"
            pagination={false}
            columns={[
              {
                dataIndex: 'sequence',
                title: '序列',
                width: 64,
              },
              {
                dataIndex: ['processing', 'name'],
                title: '行為',
                width: 120,
              },
              {
                dataIndex: ['workCenter', 'id'],
                title: '工作中心碼',
                width: 140,
              },
              {
                dataIndex: ['workCenter', 'name'],
                title: '工作中心',
                width: 220,
              },
              {
                dataIndex: 'drawingCode',
                title: '圖號',
                width: 240,
              },
            ]}
            dataSource={recipeRoutings ?? []}
          />
          <Divider orientation="left">預期產出物</Divider>
          <Table
            bordered
            id="secondTable"
            size="middle"
            pagination={false}
            columns={expectedProductionColumns}
            dataSource={materialRequestOrder?.materialRequestOrderBatches ?? []}
          />
          <Divider orientation="left">發料單投入物</Divider>
          <Table
            bordered
            id="secondTable"
            size="middle"
            pagination={false}
            columns={ingredientListColumns}
            dataSource={materialRequestOrder?.materialRequestOrderSpecs ?? []}
          />
          <Row
            gutter={[12, 12]}
            justify="center"
            style={{ width: '100%', marginTop: '20px' }}
          >
            <Col
              style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}
            >
              <Typography.Title level={5}>備料人</Typography.Title>
              <Typography.Text
                style={{ width: 150, borderBottom: '1px solid black' }}
              />
            </Col>
            <Col
              style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}
            >
              <Typography.Title level={5}>領用人</Typography.Title>
              <Typography.Text
                style={{ width: 150, borderBottom: '1px solid black' }}
              />
            </Col>
          </Row>
        </div>
      </ConfigProvider>
    </Spin>
  );
});
