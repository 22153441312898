import { Button, Icon, Message, Typography } from '@mezzanine-ui/react';
import {
  checkJWTRoles,
  shipDeliveryOrder,
  useGetDeliveryOrderDetail,
  usePalletCategories,
} from '@solar/data';
import { Col, ConfigProvider, Descriptions, Row, Table } from 'antd';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router';
import { PageLayout } from '@solar/templates';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  PalletContentMethodsFormValues,
  PalletContentTable,
} from './PalletContentTable';
import { useCallback, useMemo, useState } from 'react';
import { ChevronLeftIcon } from '@mezzanine-ui/icons';

export function DeliveryOrderDetailPage() {
  const navigate = useNavigate();
  const [isShipping, setIsShipping] = useState(false);
  const params = useParams<{ id: string }>();
  const methods = useForm<PalletContentMethodsFormValues>({
    defaultValues: { deliveryOrderList: [{}] },
  });
  const deliveryOrderListMethods = useFieldArray({
    control: methods?.control,
    name: 'deliveryOrderList',
  });

  const { deliveryOrder } = useGetDeliveryOrderDetail(params?.id);

  const { palletCategories } = usePalletCategories();
  const deliveryOrderList = methods?.watch('deliveryOrderList');

  const getTotalNetWeight = useCallback(
    (palletIndex: number) => {
      return deliveryOrderList?.[palletIndex]?.materialItems?.reduce(
        (result: number, item: any) => {
          result += Number(item?.mainMaterialUnitQuantity) ?? 0;
          return result;
        },
        0
      );
    },
    [methods?.watch]
  );

  return (
    <PageLayout
      title={`出貨單號：${deliveryOrder?.id ?? ''}`}
      extraContent={
        <Row justify="end" align="middle" style={{ height: '100%' }}>
          <Button
            type="button"
            onClick={() => {
              if (window.history.state && window.history.state.key) {
                navigate(-1);
              } else {
                navigate('/', { replace: true });
              }
            }}
            prefix={
              <Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />
            }
            variant="text"
          >
            <Typography color="text-secondary" variant="button2">
              返回上一頁
            </Typography>
          </Button>
        </Row>
      }
    >
      <ConfigProvider
        theme={{
          token: {
            borderRadius: 0,
          },
        }}
      >
        <Descriptions column={2}>
          <Descriptions.Item label="買方名稱">
            {deliveryOrder?.customerName ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="聯絡人">{''}</Descriptions.Item>
          <Descriptions.Item label="收貨方名稱">
            {deliveryOrder?.receiverName ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="聯絡電話">{''}</Descriptions.Item>
          <Descriptions.Item label="收貨方地址" span={2}>
            {deliveryOrder?.receiverAddress ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="業務備註">{''}</Descriptions.Item>
        </Descriptions>
        {deliveryOrder?.specs?.map((spec) => (
          <Descriptions
            key={spec?.orderId}
            bordered
            column={3}
            labelStyle={{ width: 120 }}
          >
            <Descriptions.Item label="訂單單號" span={2}>
              {spec?.orderId ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="訂單交期" span={1}>
              {spec?.expectedDeliveryDate
                ? moment(spec?.expectedDeliveryDate).format('YYYY-MM-DD')
                : ''}
            </Descriptions.Item>
            <Descriptions.Item
              label="訂單內容"
              span={3}
              contentStyle={{ padding: 0 }}
            >
              <Table
                style={{ border: 'none' }}
                size="small"
                rowKey="materialId"
                pagination={false}
                dataSource={spec?.materialItems ?? []}
                columns={[
                  {
                    dataIndex: 'productionDeliveryOrderLine',
                    title: '項次',
                  },
                  {
                    width: 200,
                    dataIndex: 'materialId',
                    title: '料號',
                  },
                  {
                    width: 200,
                    dataIndex: 'materialDescription',
                    title: '品名',
                  },
                  {
                    width: 100,
                    dataIndex: 'quantity',
                    title: '數量',
                  },
                  {
                    width: 100,
                    dataIndex: 'quantityUnitName',
                    title: '單位',
                  },
                  {
                    dataIndex: 'materialSellingContent',
                    title: '物料銷售內文',
                  },
                  {
                    dataIndex: 'workOrderDescription',
                    title: '生產備註',
                  },
                  {
                    dataIndex: 'workOrderDescription',
                    title: '工單作業說明',
                  },
                  {
                    dataIndex: 'deliveryNote',
                    title: '出貨備註',
                  },
                ]}
              />
            </Descriptions.Item>
          </Descriptions>
        ))}
        {checkJWTRoles([85]) && (
          <Row align="middle" justify="space-between">
            <Col>
              <Typography variant="h5">出貨內容</Typography>
            </Col>
            <Col>
              <Button
                prefix="＋"
                variant="outlined"
                onClick={() => deliveryOrderListMethods.append({})}
              >
                新增棧板
              </Button>
            </Col>
          </Row>
        )}
        {checkJWTRoles([85]) && (
          <FormFieldsWrapper
            methods={methods}
            style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
          >
            {deliveryOrderListMethods?.fields?.map((field, index, fields) => (
              <Descriptions
                bordered
                column={3}
                key={field?.id}
                labelStyle={{ width: 120 }}
              >
                <Descriptions.Item label="棧板編號">
                  {String(index + 1).padStart(3, '0')}
                </Descriptions.Item>
                <Descriptions.Item label="棧板類型">
                  <SelectField
                    registerName={`deliveryOrderList.${index}.palletCategoryId`}
                    options={palletCategories?.map((category) => ({
                      id: category?.id?.toString(),
                      name: `${category?.name} ${category?.widthCm}x${category?.lengthCm}`,
                    }))}
                  />
                </Descriptions.Item>
                <Descriptions.Item>
                  <Row justify="end">
                    <Button
                      danger
                      disabled={fields?.length === 1}
                      onClick={() => deliveryOrderListMethods.remove(index)}
                    >
                      刪除
                    </Button>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item label="總毛重">
                  <InputField
                    registerName={`deliveryOrderList.${index}.totalGrossWeight`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="總淨重">
                  {getTotalNetWeight(index) ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label="總箱數">
                  <InputField
                    registerName={`deliveryOrderList.${index}.totalNumberOfBoxes`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="材積（長）">
                  <InputField
                    registerName={`deliveryOrderList.${index}.volumeLength`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="材積（寬）">
                  <InputField
                    registerName={`deliveryOrderList.${index}.volumeWidth`}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="材積（高）">
                  <InputField
                    registerName={`deliveryOrderList.${index}.volumeHeight`}
                  />
                </Descriptions.Item>

                <Descriptions.Item
                  label="棧板內容物"
                  span={3}
                  contentStyle={{ padding: 0 }}
                >
                  <PalletContentTable methods={methods} palletIndex={index} />
                </Descriptions.Item>
              </Descriptions>
            ))}
          </FormFieldsWrapper>
        )}
        {checkJWTRoles([85]) && (
          <div
            style={{
              display: 'flex',
              gap: 'var(--mzn-spacing-2)',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Button
              variant="contained"
              loading={isShipping}
              onClick={methods?.handleSubmit(async (formState) => {
                try {
                  setIsShipping(true);
                  await shipDeliveryOrder({
                    orderId: params?.id,
                    items: formState?.deliveryOrderList?.map((item: any) => {
                      console.log(item?.unit?.id, item);
                      return {
                        palletCategoryId: item?.palletCategoryId?.id
                          ? Number(item?.palletCategoryId?.id)
                          : null,
                        totalGrossWeight: item?.totalGrossWeight,
                        totalNetWeight:
                          item?.materialItems
                            ?.reduce((result: number, item: any) => {
                              result +=
                                Number(item?.mainMaterialUnitQuantity) ?? 0;
                              return result;
                            }, 0)
                            ?.toString() ?? '',
                        totalNumberOfBoxes: item?.totalNumberOfBoxes
                          ? Number(item?.totalNumberOfBoxes)
                          : null,
                        volumeLength: item?.volumeLength,
                        volumeWidth: item?.volumeWidth,
                        volumeHeight: item?.volumeHeight,
                        materialItems: item?.materialItems?.map(
                          (material: any) => ({
                            materialBarcode: material?.materialBarcode,
                            materialInputUnitQuantity:
                              material?.mainMaterialUnitQuantity,
                            materialInputUnit: material?.quantityUnit,
                            deliveryOrderDetailId:
                              material?.deliveryOrderDetailId,
                          })
                        ),
                      };
                    }),
                  });

                  Message.success('出貨成功');
                } catch (e) {
                  console.error(e);
                  if (e instanceof Error) {
                    Message.error(JSON.parse(e.message).message);
                  }
                } finally {
                  setIsShipping(false);
                }
              })}
            >
              出貨
            </Button>
          </div>
        )}
      </ConfigProvider>
    </PageLayout>
  );
}
