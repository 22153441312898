import { DeleteOutlined } from '@ant-design/icons';
import {
  AutoCompleteField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { RowSection, useGetFormValidation } from '@solar/templates';
import { Card, Row, Space } from 'antd';
import {
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { ExpectedItem } from './out-station-panel';
import {
  checkIfLoaderIsEmpty,
  useGetMaterialById,
  useMaterials,
  useUnitConversionRatiosByMaterialId,
} from '@solar/data';
import { Checkbox, Input, Message, Typography } from '@mezzanine-ui/react';
import { CheckOutFormFields } from './out-station-panel';
import { useCallback, useMemo } from 'react';
import { useGetLoaders } from '../../../Templates/LocationAndLoaderPicker/useGetLoaders';

export function CheckoutItemCard({
  field,
  index,
  remove,
  displayFormsByMaterialBarcode,
}: {
  field: FieldArrayWithId<CheckOutFormFields, 'materialCheckOutList', 'id'>;
  index: number;
  remove: UseFieldArrayRemove;
  displayFormsByMaterialBarcode?: string | null;
}) {
  const methods = useFormContext<CheckOutFormFields>();
  const materialId = methods?.watch(
    `materialCheckOutList.${index}.material.id`
  );
  const loaderId = methods?.watch(`materialCheckOutList.${index}.toLoaderId`);
  const isExpected = field?.isExpected ?? false;

  const { materials, setParams: refetchGetMaterials } = useMaterials();
  const { material } = useGetMaterialById(materialId);
  const { data: units } = useUnitConversionRatiosByMaterialId({ materialId });
  const { loaders, refetchLoaders } = useGetLoaders({});

  const unitOptions = units?.map((unit) => ({
    id: unit?.materialInputUnit,
    name: unit?.materialInputUnitName,
  }));

  const formState = useFormState({
    control: methods.control,
    name: 'forms',
  });

  const currentBatchId = useMemo(() => {
    if (field?.isExpected) {
      const [materialId, materialSapBatchId, materialSubBatchId] =
        (field as ExpectedItem).outputMaterialBarcode?.split('_') ?? [];
      return [materialId, materialSapBatchId, materialSubBatchId].join('_');
    }

    return undefined;
  }, [field]);

  const { getErrorsByBatchId } = useGetFormValidation({
    // errors: formState?.errors?.forms,
    formState,
    registerName: 'forms',
  });

  const {
    validation: checkOutValidation,
    hasInvalidError,
    hasOOSError,
    hasOOCError,
  } = useMemo(
    () => getErrorsByBatchId(currentBatchId),
    [getErrorsByBatchId, currentBatchId]
  );

  // const onCheckLoader = async () => {
  //   try {
  //     const loaderId =
  //       methods?.getValues(`materialCheckOutList.${index}.toLoaderId`) ?? '';
  //     console.log('check loader: ', loaderId);
  //     await checkIfLoaderIsEmpty({ loaderId });
  //   } catch (err) {
  //     Message.error('該載具非空的載具');
  //     methods?.setValue(`materialCheckOutList.${index}.toLoaderId`, '');
  //   }
  // };

  const getErrorCount = useCallback(
    (target: 'invalid' | 'oos' | 'ooc') => {
      return Object.keys(checkOutValidation?.[target] ?? {}).length;
    },
    [checkOutValidation, currentBatchId]
  );

  const onDelete = () => {
    remove(index);

    if (
      field.isExpected &&
      displayFormsByMaterialBarcode === field?.outputMaterialBarcode
    ) {
      const targetForms = methods?.getValues('forms');
      delete targetForms?.[currentBatchId ?? ''];
      methods.resetField(`forms.${currentBatchId}`);
      methods.setValue('forms', targetForms);
      methods.setValue('displayFormsByMaterialBarcode', undefined);
    }
  };

  return (
    <Card
      style={{ width: 600 }}
      bodyStyle={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
    >
      <Row justify="end">
        <DeleteOutlined onClick={onDelete} />
      </Row>
      {!isExpected && (
        <RowSection label="物料條碼：">
          <Input
            onChange={(event) => {
              const searchTerm = event?.target?.value;
              refetchGetMaterials({
                searchType: 'ID',
                searchTerm: searchTerm?.split('_')?.[0],
              });
            }}
          />
        </RowSection>
      )}
      {isExpected && (
        <RowSection label="顯示題目：">
          <Checkbox
            checked={
              (field as ExpectedItem).outputMaterialBarcode ===
              methods?.watch('displayFormsByMaterialBarcode')
            }
            onChange={(event) =>
              methods?.setValue(
                'displayFormsByMaterialBarcode',
                event.target?.checked
                  ? (field as ExpectedItem)?.outputMaterialBarcode
                  : undefined
              )
            }
          />
          <Space align="center">
            {hasInvalidError && (
              <span style={{ color: 'red' }}>
                {getErrorCount('invalid')}
                個欄位不符格式
              </span>
            )}
            {hasOOSError && (
              <span style={{ color: 'red' }}>
                {getErrorCount('oos')}
                個欄位 oos
              </span>
            )}
            {hasOOCError && (
              <span style={{ color: 'red' }}>
                {getErrorCount('ooc')}
                個欄位 ooc
              </span>
            )}
          </Space>
        </RowSection>
      )}
      <RowSection label="料號：">
        {isExpected ? (
          field?.material?.id
        ) : (
          <AutoCompleteField
            registerName={`materialCheckOutList.${index}.material`}
            onSearch={(searchTerm) => {
              refetchGetMaterials({
                searchType: 'ID',
                searchTerm: searchTerm?.split('_')?.[0],
              });
            }}
            options={
              materials?.map((material) => ({
                id: material?.id,
                name: material?.id,
              })) ?? []
            }
          />
        )}
      </RowSection>
      <RowSection label="品名：">{material?.description ?? ''}</RowSection>
      {isExpected && (
        <RowSection label="批號：">
          {(field as ExpectedItem)?.outputMaterialBarcode
            ?.split('_')
            ?.slice(1, 3)
            ?.join('_') ?? ''}
        </RowSection>
      )}
      <RowSection label="載具：">
        {/* <InputField
          width={200}
          registerName={`materialCheckOutList.${index}.toLoaderId`}
          // onKeyUp={async (event) => {
          //   if (event.code === 'Enter') {
          //     await onCheckLoader();
          //   }
          // }}
          // onBlur={async () => {
          //   await onCheckLoader();
          // }}
        /> */}
        <AutoCompleteField
          registerName={`materialCheckOutList.${index}.toLoaderId`}
          options={loaders?.map(({ id }) => ({ id, name: id })) ?? []}
          onSearch={(searchTerm) => refetchLoaders({ searchTerm })}
        />
        {loaderId && (
          <Typography variant="h5">
            目前載具位置：
            {loaders.find((loader) => loader?.id === loaderId?.id)?.shelfId ??
              '無'}
          </Typography>
        )}
      </RowSection>
      <RowSection label="重量：">
        <InputField
          width={200}
          required
          type="number"
          registerName={`materialCheckOutList.${index}.outputMaterialWeightUnitQuantity`}
        />
      </RowSection>
      <RowSection label="數量：">
        <InputField
          width={200}
          required
          type="number"
          registerName={`materialCheckOutList.${index}.outputMaterialInputUnitQuantity`}
        />
        <SelectField
          style={{ marginLeft: '8px' }}
          required
          registerName={`materialCheckOutList.${index}.outputMaterialInputUnit`}
          options={unitOptions}
        />
      </RowSection>
      <RowSection label="備註：">
        <InputField registerName={`materialCheckOutList.${index}.remark`} />
      </RowSection>
      {/* <CheckboxField
        label="列印物料標籤"
        registerName={`materialCheckOutList.${index}.needPrintBarcode`}
      /> */}
    </Card>
  );
}
