import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  Popconfirm,
  Table,
} from '@mezzanine-ui/react';
import styles from './RequirementsManagementPage.module.scss';
import {
  LogisticSearchResData,
  LogisticsDataType,
} from './RequirementManagement.interface';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSearchResultColumns } from './useSearchResultColumns';
import { request } from '@solar/data';
import { TableTypes } from './RequirementsManagement.config';
import { exportCSVFile } from './exportCSVFile';

interface SearchResultProps {
  data: LogisticSearchResData | undefined;
  current: number;
  pageSize: number;
  tableType: TableTypes;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  onListChange: () => void;
  onSelectedIdsChange: (ids: string[]) => void;
}

export const SearchResult = ({
  data,
  current,
  pageSize,
  tableType,
  onPageChange,
  onPageSizeChange,
  onListChange,
  onSelectedIdsChange,
}: SearchResultProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<LogisticsDataType>();
  const deleteBtn = useRef(null);
  const [delPopperOpen, setDelPopperOpen] = useState(false);

  const onOperateBtnClick = useCallback(
    (index: number) => {
      setModalData(data?.data[index]);
      setIsModalOpen(true);
    },
    [data]
  );
  const { actionColumns, briefColumns, detailedColumns } =
    useSearchResultColumns(onOperateBtnClick);
  const columns = useMemo(() => {
    if (tableType === TableTypes.Brief) {
      return [...actionColumns, ...briefColumns];
    }

    return [...actionColumns, ...detailedColumns];
  }, [tableType, actionColumns, briefColumns, detailedColumns]);
  const expandableOptions = useMemo(() => {
    if (tableType === TableTypes.Brief) return;
    return {
      rowExpandable: (record: LogisticsDataType) => record.items.length > 1,
      expandedRowRender: (record: LogisticsDataType) => ({
        className: styles.expandedRow,
        dataSource: record.items.slice(1).map((item, index) => ({
          ...record,
          items: [item],
          id: `${record.id}-item-${index}`,
        })),
        columns: [...Array(3).fill({}), ...detailedColumns],
      }),
    };
  }, [tableType, detailedColumns]);
  const rowSelectionOptions = useMemo(() => {
    if (tableType === TableTypes.Detailed) return;
    return {
      onChange: onSelectedIdsChange,
    };
  }, [tableType, onSelectedIdsChange]);

  const modalDataIndexList = [
    'applicant',
    'applyDate',
    'logisticType',
    'confirmor',
    'confirmDate',
    'dispatchDate',
    'deliveryStartCompany',
    'deliveryEndCompany',
    'requestStatus',
    'deliveryArea',
  ];

  const modalDataMapping = (
    dataIndex: keyof LogisticsDataType,
    render?: (data: LogisticsDataType, ...props: any[]) => any
  ) => {
    const value = modalData?.[dataIndex];
    if (value === undefined) return;
    return render ? render(modalData as LogisticsDataType) : value;
  };

  const onClose = () => {
    setIsModalOpen(false);
    setModalData(undefined);
    setDelPopperOpen(false);
  };

  const onDelete = async (id?: string) => {
    if (!id) return;
    try {
      await request(`/logistics/${id}`, {
        method: 'delete',
        namespace: '/sd/sd',
        responseParser: (res) => res,
      });
      Message.success('刪除成功');
      onListChange();
      onClose();
      setDelPopperOpen(false);
    } catch (err: any) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('刪除失敗');
      }
    }
  };

  return (
    <>
      <div className={styles.exportBtn}>
        {tableType === TableTypes.Detailed ? (
          <Button
            variant="text"
            onClick={() => exportCSVFile(data?.data, detailedColumns)}
          >
            匯出
          </Button>
        ) : null}
      </div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={data?.data || []}
        scroll={{ x: tableType === TableTypes.Brief ? 2600 : 4600 }}
        rowSelection={rowSelectionOptions}
        expandable={expandableOptions}
        pagination={{
          disableAutoSlicing: true,
          total: data?.totalCount,
          current,
          onChange: (page) => onPageChange(page),
          options: {
            pageSize,
            onChangePageSize: (size) => onPageSizeChange(size),
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
        }}
      />
      <Modal size="large" open={isModalOpen} onClose={onClose}>
        <ModalBody className={styles.modalBody}>
          {columns
            .filter((column) => {
              return modalDataIndexList.includes(column?.dataIndex ?? '');
            })
            .map((column) => {
              const dataIndex = column.dataIndex as keyof LogisticsDataType;
              return (
                <>
                  <div
                    key={`${dataIndex}-header`}
                    className={styles.modalTableHeader}
                  >
                    {column.title}
                  </div>
                  <div
                    key={`${dataIndex}-data`}
                    className={
                      dataIndex === 'requestStatus'
                        ? modalData?.requestStatus
                          ? styles.confirmedCell
                          : styles.unconfirmedCell
                        : ''
                    }
                  >
                    {modalDataMapping(dataIndex, column.render)}
                  </div>
                </>
              );
            })}
        </ModalBody>
        <ModalActions
          hideConfirmButton
          cancelText="取消"
          confirmButtonProps={{
            color: modalData?.requestStatus ? 'secondary' : 'primary',
          }}
          onCancel={onClose}
        >
          <Button
            ref={deleteBtn}
            variant="outlined"
            danger
            onClick={() => setDelPopperOpen(true)}
          >
            作廢
          </Button>
        </ModalActions>
      </Modal>
      <Popconfirm
        anchor={deleteBtn}
        open={delPopperOpen}
        title="確認作廢"
        cancelText="取消"
        confirmText="確認"
        onCancel={() => setDelPopperOpen(false)}
        onConfirm={() => onDelete(modalData?.id)}
      />
    </>
  );
};
