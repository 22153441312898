export const resourcesWithRoutes = {
  12: {
    60: '/auth/tenant',
    160: '/auth/tenant', //readonly
    61: '/auth/role',
    161: '/auth/role', //readonly
  },
  13: {
    62: '/equipments',
    63: '/equipments/maintenance',
    64: '/equipments/grafana',
  },
  14: {
    65: '/facilities/electric',
  },
  15: {
    66: '/human-resources/department-information',
    158: '/human-resources/department-information', //readonly
  },
  16: {
    67: '/customer-management/trade-condition',
  },
  17: {
    68: '/order/manage',
    69: '/order/order-predict-search',
    147: '/order/cpi',
    156: '/order/virtual-out-and-in',
  },
  18: {
    70: '/sales-distribution/sales-return',
    71: '/sales-distribution/packaging',
    168: '/sales-distribution/packaging', //readonly
    72: '/sales-distribution/main-file',
    169: '/sales-distribution/main-file', //readonly
    73: '/sales-distribution/estimate',
    170: '/sales-distribution/estimate',
    74: '/sales-distribution/shipment',
    171: '/sales-distribution/shipment',
    75: '/sales-distribution/hub-delivery-order',
    76: '/sales-distribution/bill-weight-ref',
    150: '/sales-distribution/hub-receiving-order',
    242: '/sales-distribution/hub-receiving-order', //readonly
    151: '/sales-distribution/hub-warehouse',
    243: '/sales-distribution/hub-warehouse', //readonly
  },
  27: {
    77: '/target-number/number-rules/create',
    78: '/target-number/number-rules/management',
    175: '/target-number/number-rules/management', //readonly
    79: '/target-number/number-dispatcher',
    80: '/target-number/dispatched-search',
    177: '/target-number/dispatched-search', //readonly
  },
  19: {
    81: '/warehouse-enquiry',
    178: '/warehouse-enquiry', //readonly
    82: '/incoming-inspect',
    179: '/incoming-inspect', //readonly
    83: '/warehouse/material-shift',
    180: '/warehouse/material-shift', //readonly
    84: '/warehouse/stock-bind-order',
    85: '/warehouse/material-supply',
    182: '/warehouse/material-supply', //readonly
    86: '/warehouse/material-receipt-check',
    183: '/warehouse/material-receipt-check', //readonly
    87: '/warehouse/map',
    184: '/warehouse/map', //readonly
    // 88: '/warehouse/material-return-order'
    89: '/warehouse/operation-history',
    90: '/warehouse/loader-management',
    // 盤點清單: '/warehouse/tempList', // 待確認
    91: '/warehouse/miscellaneous-storage-in',
    186: '/warehouse/miscellaneous-storage-in', //readonly
    // 雜項發退料: '/warehouse/miscellaneous-dispatching',
    92: '/warehouse/miscellaneous-manage',
    187: '/warehouse/miscellaneous-manage', //readonly

    93: '/warehouse/inventory',
    188: '/warehouse/inventory', //readonly
    134: '/qualifications/incoming/purchase-view',
    228: '/qualifications/incoming/purchase-view', //readonly
    135: '/warehouse/stock-change',
    153: '/warehouse/return-purchase-order',
    245: '/warehouse/return-purchase-order', //readonly
    154: '/warehouse/stock-change',
    246: '/warehouse/stock-change', //readonly
  },
  20: {
    94: '/material-code/material-mapping',
    189: '/material-code/material-mapping', //readonly
    95: '/material-code/property-maintain',
    96: '/material-code/misc-material-inventory',
    191: '/material-code/misc-material-inventory', //readonly
    97: '/material-code/material-id-update',
    98: '/material-code/viewer',
    193: '/material-code/viewer', //readonly
    99: '/material-code/ingredientserach',
    194: '/material-code/ingredientserach', //readonly
  },
  21: {
    100: '/qualifications/1100/reports',
    195: '/qualifications/1100/reports', //readonly
    101: '/qualifications/1200/reports',
    196: '/qualifications/1200/reports', //readonly
    102: '/qualifications/send-for-inspection',
    103: '/qualifications/submission-results/inventory',
    198: '/qualifications/submission-results/inventory', //readonly
    104: '/qualifications/submission-results',
    105: '/qualifications/submission-results/copy',
    136: '/qualifications/incoming',
    229: '/qualifications/incoming', //readonly
    137: '/qualifications/incoming/user-view',
    230: '/qualifications/incoming/user-view', //readonly
  },
  22: {
    106: '/drawings/tem',
    201: '/drawings/tem',
    107: '/drawings/fcc',
    202: '/drawings/fcc',
    108: '/drawings/attribute-code-mapping-table',
    109: '/drawings/pending',
    110: '/drawings/deleted-drawing-code',
  },
  23: {
    111: '/strike-price/search',
    206: '/strike-price/search',
    112: '/strike-price/approve',
    207: '/strike-price/approve',
    113: '/strike-price/report',
  },
  24: {
    114: '/production-planning/delivery-management',
    209: '/production-planning/delivery-management', //readonly
    115: '/production-planning/work-order-management',
    210: '/production-planning/work-order-management', //readonly
    116: '/production-planning/dispatch-orders',
    117: '/production-planning/material-allocations',
    212: '/production-planning/material-allocations', //readonly
    118: '/production-planning/material-allocations-result',
    213: '/production-planning/material-allocations-result', //readonly
    119: '/production-planning/normal-manufacturing',
    120: '/production-planning/recycle-manufacturing',
    148: '/production-planning/production-forms/edit',
    241: '/production-planning/production-forms/edit', //readonly
    121: '/production-planning/wait-storage-list',
    122: '/production-planning/recipes/table',
    217: '/production-planning/recipes/table', //readonly
    123: '/production-planning/form-templates',
    124: '/production-planning/usage-prediction',
    125: '/production-planning/work-report-bi',
    220: '/production-planning/work-report-bi', //readonly
    // 隱藏
    // 126: '/production-planning/outsourcing-orders',
    127: '/production-planning/production-tracking',
    221: '/production-planning/production-tracking', //readonly
    132: '/production-planning/exchange-materials',
    133: '/production-planning/outsource-purchase-order-management',
    227: '/production-planning/outsource-purchase-order-management', //readonly
    149: '/production-planning/in-line-spc-abnormal-report',
    159: '/production-planning/in-line-spc-abnormal-report',
    152: '/production-planning/recipes/imports',
  },
  25: {
    128: '/print-management/label',
    129: '/print-management/work-order',
  },
  26: {
    130: '/transportation/requirements-management',
    131: '/transportation/management',
    138: '/transportation/to-be-confirm-list',
  },
};

export const standardOrderMenus = [
  {
    parentModuleName: '權限管理',
    parentModuleId: 12,
    childModules: [
      {
        id: 60,
        name: '使用者清單',
        path: '/auth/tenant',
      },
      {
        id: 61,
        name: '角色清單',
        path: '/auth/role',
      },
    ],
  },
  {
    parentModuleName: '設備管理',
    parentModuleId: 13,
    childModules: [
      {
        id: 62,
        name: '設備清單',
        path: '/equipments',
      },
      {
        id: 63,
        name: '工務維修',
        path: '/equipments/maintenance',
      },
      {
        id: 64,
        name: '機台資訊',
        path: '/equipments/grafana',
      },
    ],
  },
  //需隱藏
  // {
  //   parentModuleName: '廠務管理',
  //   parentModuleId: 14,
  //   childModules: [
  //     {
  //       id: 65,
  //       name: '電力系統',
  //       path: '/facilities/electric',
  //     },
  //   ],
  // },
  {
    parentModuleName: '人資系統管理',
    parentModuleId: 15,
    childModules: [
      {
        id: 66,
        name: '部門資料管理',
        path: '/human-resources/department-information',
      },
    ],
  },

  {
    parentModuleName: '客戶管理',
    parentModuleId: 16,
    childModules: [
      {
        id: 67,
        name: '客戶料號交易條件',
        path: '/customer-management/trade-condition',
      },
    ],
  },
  {
    parentModuleName: '訂單管理',
    parentModuleId: 17,
    childModules: [
      {
        id: 68,
        name: '訂單管理',
        path: '/order/manage',
      },
      {
        id: 69,
        name: '預測訂單',
        path: '/order/order-predict-search',
      },
      {
        id: 147,
        name: 'CPI報表',
        path: '/order/cpi',
      },
      {
        id: 156,
        name: '虛出虛入綁定',
        path: '/order/virtual-out-and-in',
      },
    ],
  },
  {
    parentModuleName: '出貨作業',
    parentModuleId: 18,
    childModules: [
      {
        id: 70,
        name: '銷退匹配',
        path: '/sales-distribution/sales-return',
      },
      {
        id: 71,
        name: '包材管理',
        path: '/sales-distribution/packaging',
      },
      {
        id: 72,
        name: '包裝主檔',
        path: '/sales-distribution/main-file',
      },
      {
        id: 73,
        name: '包裝預估',
        path: '/sales-distribution/estimate',
      },
      {
        id: 74,
        name: '出貨單包裝',
        path: '/sales-distribution/shipment',
      },
      {
        id: 75,
        name: 'HUB倉領用出貨',
        path: '/sales-distribution/hub-delivery-order',
      },
      {
        id: 150,
        name: 'HUB倉收貨',
        path: '/sales-distribution/hub-receiving-order',
      },
      {
        id: 151,
        name: 'HUB倉庫存清單',
        path: '/sales-distribution/hub-warehouse',
      },
      {
        id: 76,
        name: '出貨單客戶結帳重量參考',
        path: '/sales-distribution/bill-weight-ref',
      },
    ],
  },
  {
    parentModuleName: '客製靶編管理',
    parentModuleId: 27,
    childModules: [
      {
        id: 77,
        name: '靶編規則新增',
        path: '/target-number/number-rules/create',
      },
      {
        id: 78,
        name: '靶編規則管理',
        path: '/target-number/number-rules/management',
      },
      {
        id: 79,
        name: '靶編取號-訂單',
        path: '/target-number/number-dispatcher',
      },
      {
        id: 80,
        name: '已派發靶編查詢',
        path: '/target-number/dispatched-search',
      },
    ],
  },
  {
    parentModuleName: '倉庫管理',
    parentModuleId: 19,
    childModules: [
      {
        id: 81,
        name: '倉庫查詢',
        path: '/warehouse-enquiry',
      },
      {
        id: 82,
        name: '物料入庫',
        path: '/incoming-inspect', /// purchase-order
      },
      {
        id: 83,
        name: '物料移轉',
        path: '/warehouse/material-shift',
      },
      {
        id: 84,
        name: '庫存綁訂單',
        path: '/warehouse/stock-bind-order',
      },
      {
        id: 85,
        name: '倉庫發料',
        path: '/warehouse/material-supply',
      },
      {
        id: 86,
        name: '收料對點',
        path: '/warehouse/material-receipt-check',
      },
      {
        id: 87,
        name: '倉儲地圖',
        path: '/warehouse/map',
      },
      // {
      //   id: 88,
      //   name: '物料退貨',
      //   path: '/warehouse/material-return-order',
      // },
      // 需隱藏
      // {
      //   id: 89,
      //   name: '操作歷史紀錄',
      //   path: '/warehouse/operation-history',
      // },
      {
        id: 90,
        name: '載具管理',
        path: '/warehouse/loader-management',
      },
      {
        id: 91,
        name: '雜項入庫',
        path: '/warehouse/miscellaneous-storage-in',
      },
      {
        id: 92,
        name: '雜項管理',
        path: '/warehouse/miscellaneous-manage',
      },
      {
        id: 93,
        name: '盤點',
        path: '/warehouse/inventory',
      },
      {
        id: 134,
        name: '可付款清單',
        path: '/qualifications/incoming/purchase-view',
      },
      {
        id: 154,
        name: '庫存異動',
        path: '/warehouse/stock-change',
      },
      {
        id: 153,
        name: '採購單退貨',
        path: '/warehouse/return-purchase-order',
      },
    ],
  },
  {
    parentModuleName: '料號管理',
    parentModuleId: 20,
    childModules: [
      {
        id: 94,
        name: '新舊料號對應表',
        path: '/material-code/material-mapping',
      },
      {
        id: 95,
        name: '料號屬性維護',
        path: '/material-code/property-maintain', // /?tab=material_group
      },
      {
        id: 96,
        name: '費用性料號清單',
        path: '/material-code/misc-material-inventory',
      },
      {
        id: 97,
        name: '庫存料號變更',
        path: '/material-code/material-id-update',
      },
      {
        id: 98,
        name: '料號檢視器',
        path: '/material-code/viewer',
      },
      {
        id: 99,
        name: '成分查詢',
        path: '/material-code/ingredientserach',
      },
    ],
  },
  {
    parentModuleName: '品質管理',
    parentModuleId: 21,
    childModules: [
      {
        id: 100,
        name: '科工廠檢驗報告查詢',
        path: '/qualifications/1100/reports',
      },
      {
        id: 101,
        name: '環科廠檢驗報告查詢',
        path: '/qualifications/1200/reports',
      },
      {
        id: 102,
        name: '庫存送檢',
        path: '/qualifications/send-for-inspection',
      },
      {
        id: 103,
        name: '庫存結果',
        path: '/qualifications/submission-results/inventory',
      },
      {
        id: 104,
        name: '檢驗單結果',
        path: '/qualifications/submission-results',
      },
      {
        id: 105,
        name: '庫存綁檢驗結果',
        path: '/qualifications/submission-results/copy',
      },
      {
        id: 136,
        name: '進料品檢',
        path: '/qualifications/incoming',
      },
      {
        id: 137,
        name: '進料複檢核',
        path: '/qualifications/incoming/user-view',
      },
    ],
  },
  {
    parentModuleName: '圖號管理',
    parentModuleId: 22,
    childModules: [
      {
        id: 106,
        name: 'TEM圖號查詢',
        path: '/drawings/tem',
      },
      {
        id: 107,
        name: 'FCC圖號查詢',
        path: '/drawings/fcc',
      },
      {
        id: 108,
        name: '圖號代碼對應表',
        path: '/drawings/attribute-code-mapping-table',
      },
      {
        id: 109,
        name: '新增圖號',
        path: '/drawings/pending',
      },
      {
        id: 110,
        name: '已刪除圖號',
        path: '/drawings/deleted-drawing-code',
      },
    ],
  },
  {
    parentModuleName: '敲價管理',
    parentModuleId: 23,
    childModules: [
      {
        id: 111,
        name: '敲價人員',
        path: '/strike-price/search',
      },
      {
        id: 112,
        name: '核價人員',
        path: '/strike-price/approve',
      },
      {
        id: 113,
        name: '敲價報表',
        path: '/strike-price/report',
      },
    ],
  },
  {
    parentModuleName: '生產管理',
    parentModuleId: 24,
    childModules: [
      {
        id: 152,
        name: '新增題庫',
        path: '/production-planning/recipes/imports',
      },
      {
        id: 114,
        name: '交期管理',
        path: '/production-planning/delivery-management',
      },
      {
        id: 115,
        name: '工單管理',
        path: '/production-planning/work-order-management',
      },
      {
        id: 132,
        name: '換料工單',
        path: '/production-planning/exchange-materials',
      },
      {
        id: 133,
        name: '委外採購單',
        path: '/production-planning/outsource-purchase-order-management',
      },
      {
        id: 116,
        name: '發料單管理',
        path: '/production-planning/dispatch-orders',
      },
      {
        id: 117,
        name: '配料',
        path: '/production-planning/material-allocations',
      },
      {
        id: 118,
        name: '配料結果',
        path: '/production-planning/material-allocations-result',
      },
      {
        id: 119,
        name: '靶材製造',
        path: '/production-planning/normal-manufacturing',
      },
      {
        id: 120,
        name: '回收製造',
        path: '/production-planning/recycle-manufacturing',
      },
      {
        id: 148,
        name: '生產題目編輯',
        path: '/production-planning/production-forms/edit',
      },
      {
        id: 121,
        name: '待入庫清單',
        path: '/production-planning/wait-storage-list',
      },
      {
        id: 122,
        name: '生產路徑',
        path: '/production-planning/recipes/table',
      },
      {
        id: 123,
        name: '題庫管理',
        path: '/production-planning/form-templates',
      },
      {
        id: 124,
        name: '用量預測',
        path: '/production-planning/usage-prediction',
      },
      {
        id: 125,
        name: '報工明細查詢',
        path: '/production-planning/work-report-bi',
      },
      // {
      //   id: 126,
      //   name: '委外加工',
      //   path: '/production-planning/outsourcing-orders',
      // },

      {
        id: 149,
        name: 'IN LINE SPC 異常回覆報表',
        path: '/production-planning/in-line-spc-abnormal-report',
      },
      {
        id: 127,
        name: '批號追蹤',
        path: '/production-planning/production-tracking',
      },
    ],
  },
  {
    parentModuleName: '列印',
    parentModuleId: 25,
    childModules: [
      {
        id: 128,
        name: '標籤',
        path: '/print-management/label',
      },
      {
        id: 129,
        name: '工單',
        path: '/print-management/work-order',
      },
    ],
  },
  {
    parentModuleName: '派車',
    parentModuleId: 26,
    childModules: [
      {
        id: 130,
        name: '需求管理',
        path: '/transportation/requirements-management',
      },
      {
        id: 138,
        name: '待確認派車單清單',
        path: '/transportation/to-be-confirm-list',
      },

      {
        id: 131,
        name: '派車管理',
        path: '/transportation/management',
      },
    ],
  },
];
